.container-loading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  /* background-color: rgb(49, 56, 69);*/
  background-color: #ededed;
  color: #000;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  font-size: 2vmin !important;
  font-family: "Roboto", sans-serif !important;
}

.container-loading-company-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.container-loading-company-logo {
  width: 20%;
  max-width: 300px;
}

.container-loading-company-title {
  font-size: 1.2rem;
  font-weight: 500;
  width: 100%;
  text-align: center;
  margin: 1rem 0 0.5rem 0;
}

.container-loading-txt-welcome {
  font-size: 1rem;
  opacity: 0.8;
  font-weight: 500;
  width: 100%;
  text-align: center;
  margin: 0.1rem 0 0.5rem 0;
}

.fadeOut {
  opacity: 0;
  visibility: hidden;
  z-index: -10;
  transition: visibility 0.5s, opacity 0.5s;
}
.fadeIn {
  opacity: 1;
  visibility: visible;
  z-index: 1000;
  transition: opacity 0.3s;
}
