body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: overlay;
  overflow-x: overlay;
  scroll-behavior: smooth;
  user-select: none;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

/* font size responsive under 1000px 
@media only screen and (max-width: 1000px) {
  body {
    font-size: 2vmin !important;
  }
}
@media only screen and (max-height: 1000px) {
  body {
    font-size: 2vmin !important;
  }
}
*/
