.news-groupe-item-card {
  color: rgb(8, 8, 8) !important;
  font-size: 1em !important;
}

.news-groupe-item-card-title {
  max-height: 20vh;
  font-size: 0.8em !important;
}
.news-groupe-item-card-img {
  max-height: 20vh;
}

.window-integration-grid {
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
  display: grid;
  grid-gap: 2px;
  transition: all 1000ms ease;
  padding: 5px;
}
.window-integration-news-grid-template-colums-800 {
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}
.window-integration-news-grid-template-colums-800
  .window-integration-grid-item {
  height: 300px;
}
.window-integration-news-grid-template-colums-600 {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.window-integration-news-grid-template-colums-600
  .window-integration-grid-item {
  height: 300px;
}
.window-integration-news-grid-template-colums-400 {
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
.window-integration-news-grid-template-colums-400
  .window-integration-grid-item {
  height: 250px;
}

.window-integration-grid-item {
  flex: 33%;
  display: flex;
  position: relative;
  line-height: 0;
  flex-direction: column;
  transition: all 1000ms ease;
  cursor: pointer;
}

.window-integration-grid-item:hover .window-integration-news-hover-container {
  opacity: 1;
}

.window-integration-news-hover-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-image: linear-gradient(to top, #000000bf, transparent);
  opacity: 0;
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.window-integration-grid-item-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
}

.window-integration-grid-item-info-box {
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  bottom: 0;
}

.window-integration-grid-item-title-box {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 12px 16px;
  color: #fff;
  overflow: hidden;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
}

.window-integration-grid-item-title {
  font-size: 1rem;
  line-height: 24px;
  /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;*/
}
.window-integration-grid-item-subtitle {
  font-size: 0.75rem;
  line-height: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.window-integration-grid-item-action-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
