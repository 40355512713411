.youtube-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
}
.youtube-container iframe {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.youtube-player-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.searched-video {
  display: flex;
  width: 100%;
  cursor: pointer;
}

.searched-video .left-section {
  width: 30%;
  padding: 10px;
}
.searched-video .right-section {
  width: 70%;
  text-align: left;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.searched-video .img-thumbnail {
  width: 80%;
}
.searched-video .video-title {
}
.searched-video .channel-title {
  font-weight: bold;
}
