.container-btn-forgot-password {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.btn-submit-forgot-password {
  width: 45%;
  background: #58a4b0;

  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
}
.btn-cancel-forgot-password {
  width: 45%;
  background: rgba(88, 164, 176, 0.6);

  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
}
