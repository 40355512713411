.container-date-time {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease 0s;
  width: auto;
  position: relative;
}
.subcontainer-date-time {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.subcontainer-date-time-btn-setting {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  position: absolute;
  right: 0;
  transform: translateX(100%);
}

.subcontainer-date-time-btn-time-feature {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: default;
  position: absolute;
  left: 0;
  transform: translateX(-100%);
}

.time-feature-icon {
  cursor: pointer;
  color: #fff !important;
}
.date-time-setting-icon {
  cursor: pointer;
  color: #fff !important;
}

.time {
  cursor: default;
  font-size: 7em;
  text-align: center;
  line-height: 0.8;
  margin: 0;
  padding: 0;
  /* position: absolute;
  top: 10vh;*/
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* font-weight: 500;*/
}

.time-container-with-second {
  justify-content: flex-start;
  /* min-width: 400px;*/
  display: flex;
}

.time-24h-am {
  cursor: default;
  font-size: 2em;
  text-align: left;
  line-height: 0.8;
  font-weight: 500;
  margin: 0.5rem 0.5rem 0 0;
}

.date-day {
  cursor: default;
  font-size: 2em;
  text-align: center;
  margin: 0;
  padding: 0;
  /*font-weight: 900;*/
  cursor: pointer;
}

/*************** popober hour24***************/
.popover-time-hour24 {
  min-width: fit-content;
  background-color: transparent;
  border: 0;
}

.popover-time-hour24-body {
  user-select: none !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--popup-background-color);
  color: var(--popup-text-color);
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
  padding: 0 1rem;
  border-radius: var(--popup-border-raduis);
  margin-left: -1px;
  font-family: var(--theme-font-family) !important;
}

.popover-time-hour24-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
  width: 100%;
  min-width: 160px;
}

.popover-analog-clock-size-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
  width: 100%;
  min-width: 180px;
}

.popover-analog-clock-size-item-txt {
  margin-right: 20px;
}

/*************** popober time feature***************/
.popover-time-feature {
  min-width: fit-content;

  background-color: transparent;
  border: 0;
}

.popover-time-feature-body {
  user-select: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: var(--popup-background-color);
  color: var(--popup-text-color);
  font-size: 1em;
  padding: 0.5rem;
  border-radius: var(--popup-border-raduis);
  margin-left: -1px;
  font-family: var(--theme-font-family) !important;
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
}

.btn-time-feature {
  color: var(--popup-text-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.6rem;
  font-weight: 600;
  width: 50px;
  padding: 5px;
  margin: 0 3px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-time-feature:hover {
  background-color: var(--popup-search-engine-background-color);
}
.btn-time-feature:active {
  background-color: var(--popup-active-search-engine-background-color);
}
.btn-time-feature-active {
  background-color: var(--popup-active-search-engine-background-color);
}

/****Calender popover */

.calendar-popover {
  background: var(--popup-background-color);
  color: var(--popup-text-color);
  font-family: var(--theme-font-family) !important;
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
  padding: 0 !important;
  border: 0;
}
.calendar-popover-body {
  padding: 0 !important;
  margin-top: -1px;
}

.calendar-popover .popover-arrow::after {
  border-bottom-color: var(--popup-background-color) !important;
}

.rw-cell {
  color: var(--popup-text-color);
  font-family: var(--theme-font-family) !important;
}

.rw-calendar-contained {
  background: var(--popup-background-color);
  color: var(--popup-text-color);
  font-family: var(--theme-font-family) !important;
  border: none;
}
.rw-calendar-transition {
  background: var(--popup-background-color);
  color: var(--popup-text-color);
  font-family: var(--theme-font-family) !important;
}

.rw-state-selected {
  background-color: var(--popup-light-background-color) !important;
  border: none;
  color: var(--popup-text-color) !important;
  font-family: var(--theme-font-family) !important;
}
