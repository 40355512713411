.wallpaper-tabs {
  margin-bottom: 0 !important;
}
.wallpaper-tab {
  color: var(--popup-text-color) !important;
}

.wallpaper-tabs .nav-link.active {
  color: var(--popup-text-color);
  background: var(--popup-light-background-color);
  border-color: var(--meavana-box-shadow-color);
}
.wallpaper-tabs .nav-link {
  color: var(--popup-text-color);
}
