/* the button/icon for showing notes container(popup)  */
.notes-container-btn {
  z-index: 10;
  padding: 0.2rem;
  border-radius: 100px;
  color: var(--meavana-icon-color) !important;
  cursor: pointer;
  /* position: absolute;
    right: 10px;
    bottom: 10px;*/
}

.notes-container-btn:hover {
  background-color: #8da2b780 !important;
}

.popover-notes {
  background-color: transparent;
  border: 0;
  transition: all 1s ease, opacity 0.3s linear, transform 0.5s ease;

  width: 50vw !important;
  height: 50vh !important;
  padding: 0 !important;
  min-width: 50vw;
  min-height: 500px;
  /*  transform: translate3d(54.5vw, -59.6px, 0px) !important*/
}
/*
  .popover-notes::before{
    transform: translate3d(95vw, -5px, 0px) !important;
    transition: transform 0.5s ease;
  }
  .popover-notes::after{
    transform: translate3d(95vw, -5px, 0px) !important;
    transition: transform 0.5s ease;
  }
*/

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 1000px) {
  .popover-notes {
    min-width: 600px;
  }
}
@media only screen and (max-height: 800px) {
  .popover-notes {
    min-height: 500px;
  }
}

.popover-notes .popover-arrow::after {
  border-top-color: var(--popup-background-color) !important;
}

.notes-expanded-popover {
  margin: 0 !important;
  width: 99vw !important;
  min-width: 99vw !important;
  height: calc(99vh - 10px) !important;
  transform: translate3d(0.5vw, -9.6px, 0px) !important;
  transition: all 1s ease;
  /* transition: all 1s ease ,opacity .15s linear,transform .95s ease;*/
}

.popover-notes-body {
  user-select: none !important;
  color: var(--popup-text-color) !important;
  background: var(--popup-background-color);
  border-radius: var(--popup-border-raduis);
  font-family: var(--theme-font-family) !important;
  width: 100%;
  height: 100%;
  transition: all 500ms ease;
  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  scroll-behavior: smooth;
  padding: 0 !important;
  margin-bottom: -1px;
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
}
