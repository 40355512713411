.container-btn-Settings {
  z-index: 10;

  padding: 0.2rem;
  border-radius: 50%;
  color: var(--meavana-icon-color) !important;
  cursor: pointer;
}

.container-btn-Settings:hover {
  background-color: #8da3b699 !important;
}

.popover-settings .popover-arrow::after {
  border-top-color: var(--popup-background-color) !important;
}

.popover-settings {
  background-color: transparent;
  border: 0;
}

.popover-settings-body {
  user-select: none !important;
  color: var(--popup-text-color);
  background: var(--popup-background-color);
  border-radius: var(--popup-border-raduis);
  font-family: var(--theme-font-family) !important;
  transition: all 1s ease;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 0 !important;
  margin-bottom: -1px;
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
}

.Small-Size .popover-settings-body {
  font-size: var(--theme-font-small-size) !important;
  width: 35vw;
  min-width: 500px;
  min-height: 400px;
  height: 50vh;
}
.Medium-Size .popover-settings-body {
  font-size: var(--theme-font-meduim-size) !important;
  width: 45vw;
  min-width: 550px;
  min-height: 450px;

  height: 60vh;
}
.Large-Size .popover-settings-body {
  font-size: var(--theme-font-large-size) !important;
  width: 55vw;
  min-width: 600px;
  min-height: 500px;

  height: 70vh;
}

.container-settings-left {
  flex: 1;
  display: flex;
  border-right: 2px solid var(--popup-light-background-color);
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border-top-left-radius: var(--popup-border-raduis);
  border-bottom-left-radius: var(--popup-border-raduis);
}

.container-settings-left-top-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.container-settings-left-bottom-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.setting-nav-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 1rem;
}
.setting-nav-list-item {
  /* color: #fff;*/
  font-size: 1.2em;
  width: 100%;
  text-align: left;
  opacity: 0.8;
  font-weight: 400;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.setting-nav-list-item:hover {
  opacity: 0.9;
  font-weight: 500;
  background-color: var(--popup-light-background-color) !important;
}
.setting-nav-list-item:active {
  opacity: 1;
  font-weight: 500;
  background-color: var(--popup-light-background-color) !important;
}

.setting-nav-list-item-active {
  /* color: #fff;*/
  font-weight: 500;
  background-color: var(--popup-light-background-color) !important;
  font-size: 1.2em;
  width: 100%;
  text-align: left;
  padding: 0.5rem 1rem;
}

.setting-nav-sublist {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 1rem;
}

.setting-nav-sublist-item {
  /* color: #fff;*/
  font-size: 1em;
  width: 100%;
  text-align: left;
  opacity: 0.5;
  font-weight: 500;
  padding: 0.5rem 1rem;
}

.setting-nav-sublist-item:hover {
  opacity: 0.9;
  font-weight: 500;
  background-color: var(--popup-active-background-color);
}

.setting-nav-sublist-item-active {
  /* color: #fff;*/
  font-weight: 500;
  background-color: var(--popup-active-background-color);
  font-size: 1em;
  width: 100%;
  text-align: left;
  padding: 0.5rem 1rem;
}

.container-btn-icons-setting {
  opacity: 0.6;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
  color: var(--popup-text-color);
  cursor: pointer;
}

.container-btn-icons-setting-txt {
  font-size: 1em;

  font-weight: 500;
}

.container-btn-icons-setting-active {
  opacity: 1;
  background-color: var(--popup-light-background-color) !important;
}

.container-btn-icons-setting:hover {
  opacity: 0.9;
  background-color: var(--popup-light-background-color) !important;
}
.container-btn-icons-setting:active {
  opacity: 1;
  background-color: var(--popup-light-background-color) !important;
}
.container-btn-logout:hover {
  border-bottom-left-radius: var(--popup-border-raduis);
}
.container-btn-logout:active {
  border-bottom-left-radius: var(--popup-border-raduis);
}

.seeting-FormControl .seeting-FormControl-label {
  color: var(--popup-text-color) !important;
}

/*
.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: #8da2b780 !important;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 4px !important;
}

*/
.container-settings-right {
  padding: 0.4rem;
  flex: 3;
  display: flex;
  height: 100%;
  background-color: transparent;
  overflow-y: auto;
  overflow-x: hidden;
  border-top-right-radius: var(--popup-border-raduis);
  border-bottom-right-radius: var(--popup-border-raduis);
}

.settings-view {
  min-height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.header-settings {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1rem;
  width: 100%;
}

.header-settings-title {
  /*  color: #fff;*/
  font-size: 1.5em;
  width: 100%;
  text-align: left;

  font-weight: 500;
  padding: 0.5rem 0 0 0;
  margin: 0;
}

.header-settings-subtitle {
  /* color: #fff;*/
  opacity: 0.75;
  font-size: 0.8em;
  width: 100%;
  text-align: left;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.list-settings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
}

.container-contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 1rem 1rem 1rem;
}

.contact-form-group {
  width: 100%;
}

.contact-form-control {
  background-color: var(--popup-light-background-color) !important;
  color: var(--popup-text-color) !important;
}
.contact-form-control option {
  background-color: var(--popup-background-color-select-option) !important;
  color: var(--popup-text-color) !important;
}
.contact-form-submit-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem !important;
  width: 100%;
}

.input-error {
  border: 2px solid #cb0101 !important;
}

.error-msg-txt {
  color: #cb0101;
  font-size: 0.9em;
  font-weight: 500;
  width: 100%;
  padding: 0 0 0 5px;
  margin: 0;
  text-align: left;
  min-height: 22px;
}
.succes-msg-txt {
  color: var(--meavana-green-color);
  font-size: 0.9em;
  font-weight: 500;
  width: 100%;
  padding: 0 0 0 5px;
  margin: 0;
  text-align: left;
  min-height: 22px;
}

.container-contact-setting-chips {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1rem 0;
  width: 100%;
}

.button-icon-arrow-topic {
  position: absolute !important;
  right: 0;
  bottom: 0;
  color: var(--popup-text-color) !important;
  z-index: -1;
}

.contact-form-group label {
  margin: 0.3rem 0 0.2rem 0;
}

.contact-form-control-message {
  width: 100%;
  height: 18vh;
}

.contact-form-control:focus {
  border-color: var(--popup-active-search-engine-background-color);
  box-shadow: none;
}

.select-arrow-contact {
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      var(--popup-text-color) 50%
    ),
    linear-gradient(135deg, var(--popup-text-color) 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.list-settings-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  padding-top: 0.2rem;
  border-bottom: 1.4px solid var(--popup-light-background-color);
}

.list-settings-item-txt {
  /* color: #fff;*/

  font-size: 1.2em;

  text-align: left;

  font-weight: 400;
  margin: 0;
}

/*
.css-3baera-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  color: #19c522 !important;
  background-color: rgb(46 125 50 / 20%) !important;
}*/

.setting-list-groupe {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 1rem;
}

.setting-list-groupe-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 0 0 0.5rem;
}

.setting-list-groupe-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0.5rem 0;
  border-bottom: 1.4px solid var(--popup-light-background-color);
}

.setting-list-groupe-header-title {
  /*  color: #fff;*/
  font-size: 1.2em;
  width: 100%;
  text-align: left;

  font-weight: 400;
  padding: 0.5rem 0 0 0;
  margin: 0;
}

.setting-list-groupe-header-subtitle {
  /* color: #fff;*/
  opacity: 0.65;
  font-size: 0.5em;
  width: 100%;
  text-align: left;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

/******************************  About settings**************************/

.about-settings-view {
  min-height: 100%;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.about-header-settings {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1rem;
  width: 100%;
}

.about-link-chrome-store {
  opacity: 1;
  font-size: 1.2em;
  width: 100%;
  text-align: center;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.about-link-chrome-store a:link {
  color: var(--popup-text-color);
  font-weight: 700 !important;
  opacity: 0.8 !important;
  background-color: transparent;
  text-decoration: underline;
}
.about-link-chrome-store a:visited {
  color: var(--popup-text-color);
  font-weight: 700 !important;
  opacity: 0.6 !important;
  background-color: transparent;
  text-decoration: underline;
}
.about-link-chrome-store a:hover {
  color: var(--popup-text-color);
  font-weight: 700 !important;
  opacity: 1 !important;
  background-color: transparent;
  text-decoration: underline;
}
.about-link-chrome-store a:active {
  color: var(--popup-text-color);
  font-weight: 700 !important;
  opacity: 0.9 !important;
  background-color: transparent;
  text-decoration: underline;
}

.about-header-settings-title {
  /*  color: #fff;*/
  font-size: 1.5em;
  width: 100%;
  text-align: left;

  font-weight: 500;
  padding: 0.5rem 0 0 0;
  margin: 0;
}
.about-container-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 1rem;
}
.about-container-button-external {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.about-button-external-url {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: var(--popup-search-engine-background-color);*/
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;

  /* border-radius: 5px;*/
  margin: 0;
  padding: 0.3rem;
  box-shadow: 0 1px 6px 0 #20212447;
  text-transform: capitalize;
  border: 1.4px solid var(--popup-light-background-color);
}

.about-button-external-url:hover {
  color: var(--popup-search-engine-text-color);
  background-color: var(--popup-search-engine-background-color);
}
.about-button-external-url:active {
  color: var(--popup-search-engine-text-color);
  background-color: var(--popup-active-search-engine-background-color);
}

.about-container-main-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.about-societe-logo {
  /*width: 12vh;*/
  width: 35%;
  max-width: 200px;
  margin: 1rem;
}

.about-societe-title {
  font-size: 1.5em;
  text-align: center;
  font-weight: 500;
  padding: 0;
  margin: 0;
}
.about-societe-subtitle {
  font-size: 1em;
  text-align: center;
  font-weight: 500;
  padding: 0;
  margin: 0;
}
.about-societe-txt-version {
  font-size: 0.9em;
  opacity: 0.5;
  text-align: center;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.about-container-social-media {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}
.about-container-terms {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.3rem;
}
.about-txt-terms {
  font-size: 1.1em;
  font-weight: 400;
  opacity: 0.7;
  cursor: pointer;
}

.about-txt-terms:hover {
  font-weight: 500;
  opacity: 1;
}

.about-image-social-media {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin: 0.3rem;
  padding: 0.3rem;
  width: 35px;
  border-radius: 50px;
  box-shadow: 0 1px 6px 0 #20212447;
  cursor: pointer;
}
.about-image-social-media:hover {
  background-color: var(--popup-search-engine-background-color);
}
.about-image-social-media:active {
  background-color: var(--popup-active-search-engine-background-color);
}

/********************************************************/
/********************* Profle style***********************************/

.list-settings-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
}

.profile-conntainer-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.setting-profile-list-groupe-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  padding: 0.5rem 0;
  border-bottom: 1.4px solid var(--popup-light-background-color);
}

.profile-btn-activate-edit {
  font-size: 1em !important;
  font-weight: 400 !important;
  color: var(--popup-text-color) !important;
  position: absolute !important;
  right: 0;
}

.profile-btn-edit {
  position: absolute;
  right: 0;
}

.container-profile-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0;
}

.profile-form-group {
  width: 100%;
  padding: 0.5rem;
}
.profile-form-submit-area {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}
.profile-form-submit-area-action-btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.profile-form-control {
  background-color: var(--popup-light-background-color) !important;
  color: var(--popup-text-color) !important;
}

.profile-thanks-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.3rem 1rem;
}

.profile-user-since-text {
  font-size: 1em;
  align-self: center;
  font-weight: 500;
}
.profile-thanks-text {
  font-size: 1.25em;
  align-self: center;
  font-weight: 500;
  margin-bottom: 5px;
}

.container-profile-info {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
}

.profile-info-username-txt {
  font-size: 1em;
  font-weight: 500;
  margin: 0.5rem 0 0.2rem 0;
}
.profile-info-email-txt {
  opacity: 0.9;
  font-size: 0.9em;
}

/********************************************************/

.form-control {
  font-size: 1em !important;
}

.button-setting-popup {
  font-size: 1em !important;
  white-space: nowrap;
}

/*** Topic */

.topic-grid {
  /*display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;*/
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-row-gap: 0.5em;
  grid-column-gap: 0.3em;
  width: 100%;
}

.topic-grid:after,
:before {
  border: 0 solid #e3e3e3;
  box-sizing: border-box;
}
.topic-grid-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
}

.topic-grid-item span {
  width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 1em;
  align-self: center;
  font-weight: 500;
  z-index: 5;
}

.topic-grid-item-image-container {
  background-color: rgb(79 84 92 / var(--tw-bg-opacity));
  margin-bottom: 0.2em;
  border-radius: 0.5rem;
}

.topic-grid-item-image {
  height: auto;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 0.5rem;
}

.topic-grid-item-action-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-image: linear-gradient(to top, #000000bf, transparent);
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  border-radius: 0.5rem;
}

.topic-grid-item:hover .topic-grid-item-action-container {
  opacity: 1;
}

.clicked-topic-loading-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-image: linear-gradient(to top, #000000bf, transparent);
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
}

.active-topic-icon-container {
  position: absolute !important;
  cursor: pointer !important;
  top: 0;
  left: 0;
  color: #52af77 !important;
}
.inactive-topic-icon-container {
  position: absolute !important;
  cursor: pointer !important;
  top: 0;
  left: 0;
  color: #fff !important;
}
