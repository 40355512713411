.App {
  text-align: center;
  font-family: var(--theme-font-family) !important;
  background-color: #303743;
  user-select: none !important;
  position: relative;
}

body {
  --popup-border-raduis: 10px;
  font-size: var(--theme-font-meduim-size);
}

div {
  z-index: 4;
}

/************************************* Theme Modes****************************************/
.light-transparent-theme {
  --popup-background-color: rgba(255, 255, 255, 0.6);
  --popup-background-color-select-option: #ffffff;
  --popup-background-color-opaque: rgba(255, 255, 255, 0.8);
  --popup-active-background-color: rgba(0, 0, 0, 0.2);
  --popup-light-background-color: rgba(141, 162, 183, 0.5);
  --popup-border-color: #303743bf;
  --popup-border-divider-color: hsla(0, 0%, 0%, 0.2);

  --popup-text-color: #000;
  --popup-seconde-text-color: #000;
  --popup-disabled-text-color: #4a4a4b;
  --scrollbar-background-color: rgba(48, 55, 67, 0.749);
  --scrollbar-track-color: rgba(141, 162, 183, 0.5);
  --scrollbar-track-border-color: rgba(255, 255, 255, 0.6);

  --popup-active-search-engine-background-color: rgba(0, 0, 0, 0.4);
  --popup-active-search-engine-text-color: #fff;
  --popup-search-engine-text-color: #000;
  --popup-hover-search-engine-background-color: #008b8b;
  --popup-search-engine-background-color: rgba(0, 0, 0, 0.2);
  --meavana-green-color: #52af77;
}
.light-translucent-theme {
  --popup-background-color: rgba(255, 255, 255, 0.8);
  --popup-background-color-select-option: #ffffff;
  --popup-background-color-opaque: rgba(255, 255, 255, 1);
  --popup-active-background-color: rgba(0, 0, 0, 0.2);
  --popup-light-background-color: rgba(141, 162, 183, 0.5);
  --popup-border-color: #303743bf;
  --popup-border-divider-color: hsla(0, 0%, 0%, 0.2);

  --popup-text-color: #000;
  --popup-seconde-text-color: #000;
  --popup-disabled-text-color: #4a4a4b;
  --scrollbar-background-color: #303743bf;
  --scrollbar-track-color: rgba(141, 162, 183, 0.5);
  --scrollbar-track-border-color: rgba(255, 255, 255, 0.8);

  --popup-active-search-engine-background-color: rgba(0, 0, 0, 0.4);
  --popup-active-search-engine-text-color: #fff;
  --popup-search-engine-text-color: #000;
  --popup-hover-search-engine-background-color: #008b8b;
  --popup-search-engine-background-color: rgba(0, 0, 0, 0.2);
  --meavana-green-color: #52af77;
}

.light-opaque-theme {
  --popup-background-color: rgb(255, 255, 255);
  --popup-background-color-select-option: #ffffff;
  --popup-background-color-opaque: rgb(255, 255, 255, 1);
  --popup-active-background-color: rgba(0, 0, 0, 0.2);
  --popup-light-background-color: rgba(141, 162, 183, 0.5);
  --popup-border-color: #303743bf;
  --popup-border-divider-color: hsla(0, 0%, 0%, 0.2);

  --popup-text-color: #000;
  --popup-seconde-text-color: #000;
  --popup-disabled-text-color: #4a4a4b;
  --scrollbar-background-color: #303743bf;
  --scrollbar-track-color: rgba(141, 162, 183, 0.5);
  --scrollbar-track-border-color: rgb(255, 255, 255);

  --popup-active-search-engine-background-color: rgba(0, 0, 0, 0.4);
  --popup-active-search-engine-text-color: #fff;
  --popup-search-engine-text-color: #000;
  --popup-hover-search-engine-background-color: #008b8b;
  --popup-search-engine-background-color: rgba(0, 0, 0, 0.2);
  --meavana-green-color: #52af77;
}

.dark-transparent-theme {
  --popup-background-color: rgba(49, 56, 69, 0.6);
  --popup-background-color-select-option: #313845;
  --popup-background-color-opaque: rgba(49, 56, 69, 0.8);
  --popup-active-background-color: rgba(255, 255, 255, 0.302);
  --popup-light-background-color: rgba(141, 162, 183, 0.5);
  --popup-border-color: #fff;
  --popup-border-divider-color: hsla(0, 0%, 100%, 0.302);

  --popup-text-color: #fff;
  --popup-seconde-text-color: #fff;
  --popup-disabled-text-color: #4a4a4b;
  --scrollbar-background-color: #fff;
  --scrollbar-track-color: rgba(141, 162, 183, 0.5);
  --scrollbar-track-border-color: rgba(49, 56, 69, 0.6);
  --popup-active-search-engine-background-color: rgba(255, 255, 255, 0.5);
  --popup-active-search-engine-text-color: #fff;
  --popup-search-engine-text-color: #fff;
  --popup-hover-search-engine-background-color: #008b8b;
  --popup-search-engine-background-color: rgba(255, 255, 255, 0.302);
  --meavana-green-color: #52af77;
}
.dark-translucent-theme {
  --popup-background-color: rgba(49, 56, 69, 0.8);
  --popup-background-color-select-option: #313845;
  --popup-background-color-opaque: rgba(49, 56, 69, 1);
  --popup-active-background-color: rgba(255, 255, 255, 0.302);
  --popup-light-background-color: rgba(141, 162, 183, 0.5);
  --popup-border-color: #fff;
  --popup-border-divider-color: rgba(255, 255, 255, 0.302);

  --popup-text-color: #fff;
  --popup-seconde-text-color: #fff;
  --popup-disabled-text-color: #4a4a4b;
  --scrollbar-background-color: #fff;
  --scrollbar-track-color: rgba(141, 162, 183, 0.5);
  --scrollbar-track-border-color: rgba(49, 56, 69, 0.8);
  --popup-active-search-engine-background-color: rgba(255, 255, 255, 0.5);
  --popup-active-search-engine-text-color: #fff;
  --popup-search-engine-text-color: #fff;
  --popup-hover-search-engine-background-color: #008b8b;
  --popup-search-engine-background-color: rgba(255, 255, 255, 0.302);
  --meavana-green-color: #52af77;
}

.dark-opaque-theme {
  --popup-background-color: #313845;
  --popup-background-color-select-option: #313845;
  --popup-background-color-opaque: rgb(49, 56, 69, 1);
  --popup-active-background-color: rgba(255, 255, 255, 0.302);
  --popup-light-background-color: rgba(141, 162, 183, 0.5);
  --popup-border-color: #fff;
  --popup-border-divider-color: hsla(0, 0%, 100%, 0.302);

  --popup-text-color: #fff;
  --popup-seconde-text-color: #fff;
  --popup-disabled-text-color: #4a4a4b;
  --scrollbar-background-color: #fff;
  --scrollbar-track-color: rgba(141, 162, 183, 0.5);
  --scrollbar-track-border-color: rgb(49, 56, 69);
  --popup-active-search-engine-background-color: rgba(255, 255, 255, 0.5);
  --popup-active-search-engine-text-color: #fff;
  --popup-search-engine-text-color: #fff;
  --popup-hover-search-engine-background-color: #008b8b;
  --popup-search-engine-background-color: rgba(255, 255, 255, 0.302);
  --meavana-green-color: #52af77;
}

/***** * meavana mode****************************************************/

.meavana-transparent-theme {
  --popup-background-color: rgb(51, 15, 150, 0.6);
  --popup-background-color-select-option: #313845;
  --popup-background-color-opaque: rgba(49, 56, 69, 0.8);
  --popup-active-background-color: rgba(255, 255, 255, 0.302);
  --popup-light-background-color: rgba(141, 162, 183, 0.5);
  --popup-border-color: #fff;
  --popup-border-divider-color: hsla(0, 0%, 100%, 0.302);

  --popup-text-color: #fff;
  --popup-seconde-text-color: #fff;
  --popup-disabled-text-color: #4a4a4b;
  --scrollbar-background-color: #fff;
  --scrollbar-track-color: rgba(141, 162, 183, 0.5);
  --scrollbar-track-border-color: rgb(51, 15, 150, 0.6);
  --popup-active-search-engine-background-color: rgba(255, 255, 255, 0.5);
  --popup-active-search-engine-text-color: #fff;
  --popup-search-engine-text-color: #fff;
  --popup-hover-search-engine-background-color: #008b8b;
  --popup-search-engine-background-color: rgba(255, 255, 255, 0.302);
  --meavana-green-color: #52af77;
}
.meavana-translucent-theme {
  --popup-background-color: rgb(51, 15, 150, 0.8);
  --popup-background-color-select-option: #313845;
  --popup-background-color-opaque: rgba(49, 56, 69, 1);
  --popup-active-background-color: rgba(255, 255, 255, 0.302);
  --popup-light-background-color: rgba(141, 162, 183, 0.5);
  --popup-border-color: #fff;
  --popup-border-divider-color: hsla(0, 0%, 100%, 0.302);

  --popup-text-color: #fff;
  --popup-seconde-text-color: #fff;
  --popup-disabled-text-color: #4a4a4b;
  --scrollbar-background-color: #fff;
  --scrollbar-track-color: rgba(141, 162, 183, 0.5);
  --scrollbar-track-border-color: rgb(51, 15, 150, 0.8);
  --popup-active-search-engine-background-color: rgba(255, 255, 255, 0.5);
  --popup-active-search-engine-text-color: #fff;
  --popup-search-engine-text-color: #fff;
  --popup-hover-search-engine-background-color: #008b8b;
  --popup-search-engine-background-color: rgba(255, 255, 255, 0.302);
  --meavana-green-color: #52af77;
}
.meavana-opaque-theme {
  --popup-background-color: rgb(51, 15, 150, 1);
  --popup-background-color-select-option: #313845;
  --popup-background-color-opaque: rgb(49, 56, 69, 1);
  --popup-active-background-color: rgba(255, 255, 255, 0.302);
  --popup-light-background-color: rgba(141, 162, 183, 0.5);
  --popup-border-color: #fff;
  --popup-border-divider-color: hsla(0, 0%, 100%, 0.302);

  --popup-text-color: #fff;
  --popup-seconde-text-color: #fff;
  --popup-disabled-text-color: #4a4a4b;
  --scrollbar-background-color: #fff;
  --scrollbar-track-color: rgba(141, 162, 183, 0.5);
  --scrollbar-track-border-color: rgb(51, 15, 150, 1);
  --popup-active-search-engine-background-color: rgba(255, 255, 255, 0.5);
  --popup-active-search-engine-text-color: #fff;
  --popup-search-engine-text-color: #fff;
  --popup-hover-search-engine-background-color: #008b8b;
  --popup-search-engine-background-color: rgba(255, 255, 255, 0.302);
  --meavana-green-color: #52af77;
}

/******************************************************************************************/
/****************************************** Global Font ************************************************/
.Classic-Font {
  --theme-font-family: "Roboto", sans-serif;
  --theme-font-stroke-width: 0.05em;
  --theme-font-small-size: 1.5vmin;
  --theme-font-meduim-size: 2vmin;
  --theme-font-large-size: 2.5vmin;
  font-family: var(--theme-font-family) !important;
}

.Professional-Font {
  --theme-font-family: "Lato", sans-serif;
  --theme-font-stroke-width: 0.05em;
  --theme-font-small-size: 1.5vmin;
  --theme-font-meduim-size: 2vmin;
  --theme-font-large-size: 2.5vmin;
  font-family: var(--theme-font-family) !important;
}

.Old-Fashioned-Font {
  --theme-font-family: "EB Garamond", serif;
  --theme-font-stroke-width: 0.04em;
  --theme-font-small-size: 1.5vmin;
  --theme-font-meduim-size: 2vmin;
  --theme-font-large-size: 2.5vmin;
  font-family: var(--theme-font-family) !important;
}

.Modern-Font {
  --theme-font-family: "Montserrat", sans-serif;
  --theme-font-stroke-width: 0.05em;
  --theme-font-small-size: 1.5vmin;
  --theme-font-meduim-size: 2vmin;
  --theme-font-large-size: 2.5vmin;
  font-family: var(--theme-font-family) !important;
}
.Fun-Font {
  --theme-font-family: "Lobster", cursive;
  --theme-font-stroke-width: 0em;
  --theme-font-small-size: 1.5vmin;
  --theme-font-meduim-size: 2vmin;
  --theme-font-large-size: 2.5vmin;
  font-family: var(--theme-font-family) !important;
}
.Artistic-Font {
  --theme-font-family: "Caveat", cursive;
  --theme-font-stroke-width: 0.04em;
  --theme-font-small-size: 1.5vmin;
  --theme-font-meduim-size: 2vmin;
  --theme-font-large-size: 2.5vmin;
  font-family: var(--theme-font-family) !important;
}
.Cute-Font {
  --theme-font-family: "Cookie", cursive;
  --theme-font-stroke-width: 0em;
  --theme-font-small-size: 1.5vmin;
  --theme-font-meduim-size: 2vmin;
  --theme-font-large-size: 2.5vmin;
  font-family: var(--theme-font-family) !important;
}
.Bold-Font {
  --theme-font-family: "Josefin Sans", sans-serif;
  --theme-font-stroke-width: 0.05em;
  --theme-font-small-size: 1.5vmin;
  --theme-font-meduim-size: 2vmin;
  --theme-font-large-size: 2.5vmin;
  font-family: var(--theme-font-family) !important;
}
.Minimalist-Font {
  --theme-font-family: "PT Sans Narrow", sans-serif;
  --theme-font-stroke-width: 0.05em;
  --theme-font-small-size: 1.8vmin;
  --theme-font-meduim-size: 2.3vmin;
  --theme-font-large-size: 2.8vmin;
  font-family: var(--theme-font-family) !important;
}

/******************************************************************************************/
/****************************************** Global Text Size ************************************************/
.Small-Size {
  font-size: var(--theme-font-small-size) !important;
}
.Medium-Size {
  font-size: var(--theme-font-meduim-size) !important;
}
.Large-Size {
  font-size: var(--theme-font-large-size) !important;
}

.background-home-picture {
  --meavana-background-color: transparent;
  --meavana-icon-color: rgb(255, 255, 255);
  --meavana-box-shadow-color: #20212447;
  --meavana-wether-icon-opacity: 100%;
}
.background-home-dark {
  --meavana-background-color: #303743;
  --meavana-icon-color: rgb(255, 255, 255);
  --meavana-box-shadow-color: #5a5c6482;
  --meavana-wether-icon-opacity: 100%;
}
.background-home-light {
  --meavana-background-color: #ededed;
  --meavana-icon-color: #5a606a;
  --meavana-box-shadow-color: #20212447;
  --meavana-wether-icon-opacity: 70%;
}

/************* scroll bar style*****************/

::-webkit-scrollbar {
  width: 7px !important;
  height: 5px !important;
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
  /* border: 3px solid var(--scrollbar-track-border-color);*/
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: var(--scrollbar-background-color);
  cursor: pointer !important;
}
