/********************** new style ******************************/

.outer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  min-height: 100vh;
  font-size: 2vmin !important;
  font-family: "Roboto", sans-serif !important;
  /*color: white;*/
  width: 100vw;
  z-index: 4;
}

.outer .form-control {
  border: 2px solid #fff;
}

.outer .form-control:focus {
  border: 2px solid #58a4b0 !important;
  box-shadow: none;
}

.outer h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}
.outer .form-group {
  padding: 0;
}

.outer .form-group label {
  padding: 5px 0;
  font-weight: 500;
}

.container-guest-mode {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.guest-mode-txt {
  font-size: 1em;
  font-weight: 500;
  text-align: center;
  opacity: 0.9;
  color: #fff;
}

.guest-mode-txt:hover {
  opacity: 1;
  text-decoration: underline;
}

.guest-mode-txt:active {
  opacity: 0.75;
  text-decoration: none;
}

.container-label-password-register {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 5px 0;
}

.container-label-password-register span {
  font-size: 0.8em;
  color: #4a4a4b;
  font-weight: 600;
  margin-left: 5px;
}
.container-label-password-register label {
  padding: 0 !important;
}

.outer .custom-checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0 5px 0;
}

.outer .custom-checkbox input {
  margin-right: 5px;
  width: 16px;
  height: 16px;
}

.btn-auth {
  width: 100%;
  background: #58a4b0;

  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
}

.btn-guest-mode {
  /*width: 30%;*/
  flex: 1;
  background: #58a4b0;
  font-size: 1em;
  font-weight: 500;
  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
}

.custom-control-label {
  font-weight: 400;
}

.container-no-account {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  position: relative;
}
.container-txt-redirect-to-register {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.container-txt-redirect-to-login {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding-top: 15px;
}

.txt-before-action {
  text-align: center;
  font-size: 0.8rem;

  color: #000;
  margin: 0;
}

.txt-redirect-action {
  color: #fff;
  margin: 0;
  cursor: pointer;
  opacity: 0.9;
  white-space: nowrap;
}

.txt-redirect-action:hover {
  text-decoration: underline;
  opacity: 1;
}

.error-msg-txt {
  color: #cb0101;
  font-size: 0.9rem;
  font-weight: 500;
  width: 100%;
  padding: 0 0 0 5px;
  margin: 0;
  text-align: left;
  min-height: 22px;
  text-transform: capitalize;
}
.succes-msg-txt {
  color: var(--meavana-green-color);
  font-size: 0.9rem;
  font-weight: 500;
  width: 100%;
  padding: 0 0 0 5px;
  margin: 0;
  text-align: left;
  min-height: 22px;
  text-transform: capitalize;
}

.input-error {
  border: 2px solid #cb0101 !important;
}

.container-input-icon-auth {
  width: 100% !important;
  display: block !important;
  position: relative;
}
.container-input-icon-auth input {
  width: 100% !important;
  display: block !important;
}

.button-icon-auth {
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
  height: 100%;
}

.inner {
  width: 30vw;
  min-width: 400px;
  max-width: 520px;
  margin: auto;
  transition: all 0.3s;
  border-radius: 20px;

  box-shadow: 0 0 1rem 5px rgb(0 0 0 / 20%);
  background-color: rgb(255 255 255 / 20%);
  backdrop-filter: blur(5px) saturate(110%) contrast(90%) brightness(95%);
  /*backdrop-filter: blur(4px) saturate(100%) contrast(60%) brightness(120%); // old one*/
  color: #000000;
  max-height: 95vh;
  /*overflow-y: hidden;*/
  position: relative;
}

.inner-subcontainer {
  padding: 1rem 1.5rem;
}

/***************** Auth tabs***********************/

.auth-tabs-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.login-tab-container {
  width: 50%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0.3rem;
  border-top-left-radius: 20px;
  cursor: pointer;
}

.sign-up-tab-container {
  width: 50%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0.3rem;
  border-top-right-radius: 20px;
  cursor: pointer;
}

.inactive-auth-tab-container {
  background-color: rgba(141, 162, 183, 0.5);
  border-top: 2px solid rgba(141, 162, 183, 0.5);
}
.inactive-auth-tab-container span {
  color: #000;
  opacity: 0.5;
  font-size: 1.5rem;
}
.active-auth-tab-container {
  border-top: 2px solid #58a4b0;
}
.active-auth-tab-container span {
  color: #000;
  opacity: 1;
  font-size: 1.5rem;
}

/**************************/
.tab {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: rgba(179, 178, 178, 0.7);
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 1.3em;
  font-weight: 500;
  opacity: 0.5;
  width: 50%;
}

.tablinks-login {
  border-top-left-radius: 20px;
  border-right: 1.5px solid #58a4b0 !important;
}

.tablinks-signin {
  border-top-right-radius: 20px;
  border-left: 1.5px solid #58a4b0 !important;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: rgba(140, 140, 140, 0.3);
  opacity: 0.7;
}

/* Create an active/current tablink class */
.tab button.active {
  opacity: 1;
  background-color: transparent;
}

.facebook-sso-button {
  padding: 5px !important;
}
.facebook-sso-button img {
  width: 35px;
  height: 35px;
}
.Twitter-sso-button {
  padding: 5px !important;
}
.Twitter-sso-button img {
  width: 35px;
  height: 35px;
}

/*/===========================
//                           
//   ####   ####   #####   
//  ##     ##     ##   ##  
//   ###    ###   ##   ##  
//     ##     ##  ##   ##  
//  ####   ####    #####   
//                           
//===========================*/

.login-sso-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 15px;
}

.login-sso-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.google-sso-button {
  padding: 5px !important;
  position: relative;
}
.google-sso-button img {
  width: 35px;
  height: 35px;
}

.btn-login-sso {
  width: 30%;
  background: #58a4b0;
  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
}

/*==========================================================================================================
//                                                                                                          
//   ####   #####   ##     ##  ######    ###     ####  ######        #####   #####   #####    ###    ###  
//  ##     ##   ##  ####   ##    ##     ## ##   ##       ##          ##     ##   ##  ##  ##   ## #  # ##  
//  ##     ##   ##  ##  ## ##    ##    ##   ##  ##       ##          #####  ##   ##  #####    ##  ##  ##  
//  ##     ##   ##  ##    ###    ##    #######  ##       ##          ##     ##   ##  ##  ##   ##      ##  
//   ####   #####   ##     ##    ##    ##   ##   ####    ##          ##      #####   ##   ##  ##      ##  
//                                                                                                          
//==========================================================================================================*/

.inner-contact {
  width: 30vw;
  min-width: 400px;
  max-width: 520px;
  margin: auto;
  transition: all 0.3s;
  border-radius: 20px;

  box-shadow: 0 0 1rem 5px rgb(0 0 0 / 20%);
  background-color: rgb(255 255 255 / 20%);
  backdrop-filter: blur(4px) saturate(100%) contrast(60%) brightness(120%);
  color: #000000;
}

.inner-contact-subcontainer {
  padding: 1rem 1.5rem;
  max-height: 95vh;
  overflow-y: auto;
}

.contact-title {
  padding: 10px 16px 14px 16px;
  transition: 0.3s;
  font-size: 1.5em;
  font-weight: 500;
  width: 100%;
  text-align: center;
  text-transform: capitalize;
}
.button-icon-arrow-topic {
  position: absolute !important;
  right: 0;
  color: #000 !important;
  z-index: 1;
}

select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.contact-textarea {
  min-height: calc(7em + 0.75rem + 2px) !important;
}
