.container-btn-share-meavana {
  z-index: 10;
  padding: 0.2rem;
  border-radius: 50%;
  color: var(--meavana-icon-color) !important;
  cursor: pointer;
  /* margin-left: 0.5rem;*/
}

.container-btn-share-meavana:hover {
  background-color: #8da3b699 !important;
}

.popover-share-meavana .popover-arrow::after {
  border-top-color: var(--popup-background-color) !important;
}

.popover-share-meavana {
  background: var(--popup-background-color);
  color: var(--popup-text-color);
  border-radius: var(--popup-border-raduis);
  margin-bottom: -1px;
  font-family: var(--theme-font-family) !important;
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;

  border: 0;
  /*  min-width: 280px;*/
}

.Small-Size .popover-share-meavana {
  font-size: var(--theme-font-small-size) !important;
}
.Medium-Size .popover-share-meavana-body {
  font-size: var(--theme-font-meduim-size) !important;
}
.Large-Size .popover-share-meavana-body {
  font-size: var(--theme-font-large-size) !important;
}

.popover-share-meavana-body {
  user-select: none !important;
  background: transparent;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
}

.share-meavana-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 5px;
  width: 100%;
}

.share-meavana-grid-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  padding: 5 8px;
}
.share-meavana-grid-item:hover {
  background: var(--popup-light-background-color);
}
.share-meavana-grid-item-active {
  background: var(--popup-light-background-color);
}

.share-meavana-grid-item img {
  width: 3em;
}
.share-meavana-grid-item span {
  font-size: 0.7em;
  padding: 5px;
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  font-weight: 500;
}
