.container-search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: calc(0.5 * 44px);
  padding: 3px;
  background: transparent;
  min-width: 300px;

  position: relative;
  height: 44px;

  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
  font-size: 2vmin !important;
  font-family: "Roboto", sans-serif !important;
}

.search-engine-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 0.25rem 0;
}
.icon-search-container {
  width: 40px;
  background: transparent;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-left: 10px;*/
}

.form-search-google {
  display: flex;
  width: 100%;
}

.search-engine-icon {
  width: 25px;
  margin-right: 5px;
}
.search-engine-active-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
.search-engine-active-icon {
  height: 30px;
  width: 30px;
}

.search-engine-active-icon :hover {
  border: 1px solid #a3a3a3;
}

.searchTerm {
  width: 100%;
  border: none;
  border-right: none;
  background: transparent;
  padding: 5px;
  margin-right: 5px;
  height: 34px;
  outline: none;
  color: #000000;
  font-size: 17px !important;
  font-weight: 500;
}

.container-search-engines {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.search-engine-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  cursor: pointer;
  margin: 1px 0;
  font-size: 1em;
  /*  font-weight: 600;*/
  color: var(--popup-text-color);
  width: 100%;
  border-radius: 5px;
}

.search-engine-item:hover {
  background: var(--popup-hover-search-engine-background-color);
}
.search-engine-item .icon-delete-search-engine {
  opacity: 0;
}
.search-engine-item-active .icon-delete-search-engine {
  opacity: 0;
}
.search-engine-item:hover .icon-delete-search-engine {
  opacity: 1;
}
.search-engine-item-active:hover .icon-delete-search-engine {
  opacity: 1;
}

.search-engine-item-active {
  background: var(--popup-hover-search-engine-background-color);
  color: var(--popup-active-search-engine-text-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: 1px 0;
  /* font-size: 1em;*/
  font-weight: 600;
  width: 100%;
  border-radius: 5px;
}

.search-engine-item-add {
  color: var(--popup-search-engine-text-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: center;

  margin-top: 3px !important;
  border-radius: 50px !important;
  padding: 7px !important;
  width: fit-content !important;
  min-width: 0 !important;
}

/******** popover search engine*****************/
.search-engines-popover {
  background: transparent;
  border: 0;
}

.search-engines-popover-body {
  background: var(--popup-background-color);
  color: var(--popup-text-color);
  border-radius: var(--popup-border-raduis);
  padding: 0.5rem 0.5em;
  max-height: 45vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  margin-top: -1px;
  font-family: var(--theme-font-family) !important;
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
}

.search-engines-popover-body::-webkit-scrollbar-track {
  margin: 0.5rem;
  margin-left: 0 !important;
}

.container-search-engine-img {
  width: inherit;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 9px 0 9px 5px;
}

.icon-delete-search-engine {
  border-radius: 13px !important;
  color: var(--popup-active-search-engine-text-color) !important;
}

.icon-delete-search-engine-hide {
  padding: 10px;
}

.icon-delete-search-engine:hover {
  color: red !important;
}

/**************************** dialog add search engine ***************/

.dialog-add-engine {
  border: 0;
}

.dialog-add-engine-content {
  padding: 10px !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  background-color: var(--popup-background-color) !important;
  color: var(--popup-text-color) !important;
  border-radius: var(--popup-border-raduis) !important;
}
.css-uhb5lp {
  background-color: var(--popup-background-color) !important;
  color: var(--popup-text-color) !important;
  border-radius: var(--popup-border-raduis) !important;
}

.container-dialog-active-engine {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 5px;
  border-bottom: 2px solid var(--popup-light-background-color);
}

.item-dialog-active-engine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--popup-search-engine-background-color);
  margin: 1px 0;
  cursor: pointer;
  font-size: 1em;
  /*font-weight: 600;*/
  color: var(--popup-search-engine-text-color);
  border-radius: 50px;
  padding-left: 10px;
  width: 175px;
  margin: 9px;
}

.container-dialog-list-engine {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 20px;
  border-bottom: 2px solid var(--popup-light-background-color);
}

.item-dialog-list-engine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--popup-search-engine-background-color);
  margin: 1px 0;
  font-size: 1em;
  cursor: pointer;

  color: var(--popup-search-engine-text-color);
  border-radius: 50px;
  padding-left: 10px;
  width: 175px;
  margin: 9px;
}

.icon-add-search-engine {
  border-radius: 13px !important;
  color: var(--popup-search-engine-text-color) !important;
}

.icon-add-search-engine:hover {
  color: rgb(3, 173, 3) !important;
}

.txt-no-search-engine {
  text-align: center;
  font-family: var(--theme-font-family);

  font-size: 1.4rem;
}

.dialog-add-engine-title {
  font-size: 1.5rem !important;
  color: var(--popup-search-engine-text-color);
}

.suggest-new-search-engine-container-open-dialog {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 10px 10px 20px;
}

.dialog-add-engine-btn {
  background: var(--popup-hover-search-engine-background-color);
  color: var(--popup-active-search-engine-text-color) !important;
  font-family: var(--theme-font-family) !important;
  font-size: 1rem !important;
}

/********************   Popover setting search engine *******************************************************/

.popover-search-engine-setting {
  min-width: fit-content;

  background-color: transparent;
  border: 0;
}

.popover-search-engine-setting-body {
  user-select: none !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--popup-background-color);
  color: var(--popup-text-color);

  padding: 0 1rem;
  border-radius: var(--popup-border-raduis);
  margin-left: -1px;
  font-family: var(--theme-font-family) !important;
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
}

.popover-search-engine-setting-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
  font-size: 1em;
}

.search-engine-setting-icon {
  position: absolute !important;
  right: -50px;
  cursor: pointer;

  color: #fff !important;
}

.rectangle-search-engine {
  border-radius: 8px !important;
}
.border-search-engine {
  border: 2px solid rgba(255, 255, 255, 0.752);
}
.border-bottom-search-engine {
  border-bottom: 2px solid rgba(255, 255, 255);
  background-color: transparent;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.border-bottom-search-engine:focus-within {
  -webkit-box-shadow: 0px 15px 15px -15px rgba(0, 0, 0, 0.4) !important;
  -moz-box-shadow: 0px 15px 15px -15px rgba(0, 0, 0, 0.4) !important;
  box-shadow: 0px 15px 15px -15px rgba(0, 0, 0, 0.4) !important;
}
