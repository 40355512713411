.popover-notes-guest-mode {
  background-color: transparent;
  border: 0;
}

.popover-notes-guest-mode .popover-arrow::after {
  border-top-color: var(--popup-background-color) !important;
}

.popover-notes-guest-mode-body {
  user-select: none !important;
  color: var(--popup-text-color) !important;
  background: var(--popup-background-color);
  border-radius: var(--popup-border-raduis);
  font-family: var(--theme-font-family) !important;
  transition: all 500ms ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0.7rem !important;
  padding-bottom: 0.5rem !important;
  margin-bottom: -1px;
}

.txt-note-guest-mode {
  font-size: 1.1em;
  text-align: center;
}
