.container-quote {
  height: 100%;
  width: 100%;
  cursor: pointer;
  user-select: none !important;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: auto !important;
  padding-right: 50px;
}

.container-quote-action {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-quote-setting {
  position: absolute !important;
  right: -50px !important;
}

.container-quote-action span {
  font-size: 1.4em;
  font-weight: 600;

  text-align: center;
  clear: both;

  /*s
  display: block; 
  white-space: nowrap;
  
   animation: txt-moving 25s linear infinite;
 
  animation-play-state: running;
   white-space:nowrap*/
}

.quote-icon-font-size {
  font-size: 1.3em !important;
  border-radius: 50%;
  color: var(--meavana-icon-color) !important;
}

.quote-popover-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
  width: 100%;
  min-width: 180px;
}

.quote-popover-item-txt {
  margin-right: 20px;
}

.popover-quote-setting {
  min-width: fit-content;

  background-color: transparent;
  border: 0;
}

.popover-quote-setting-body {
  user-select: none !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--popup-background-color);
  color: var(--popup-text-color);

  padding: 0 1rem;
  border-radius: var(--popup-border-raduis);
  margin-left: -1px;
  font-family: var(--theme-font-family) !important;
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
}

.quote-setting-icon {
  position: absolute !important;
  right: -50px;
  cursor: pointer;

  color: #fff !important;
}

/*
.container-quote:hover span {
/*
 animation: animation-quote-scroll 20s infinite;
  animation-timing-function: linear;
 white-space: normal;
 animation-play-state: paused;
  transition: animation-play-state 500ms ease;
}
*/
@-webkit-keyframes txt-moving {
  0% {
    transform: translateX(100%);
  }
  25% {
    transform: translateX(50%);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes txt-moving {
  0% {
    transform: translateX(100%);
  }
  25% {
    transform: translateX(50%);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes animation-quote-scroll {
  0% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes sliding {
  100% {
    left: 0;
    transform: translateX(-100%);
  }
}

.container-quote-metgadata {
  /* width: 420px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1em;
}

.quote-metgadata-popover {
  user-select: none !important;
  background: transparent;
  border: 0;
  min-width: fit-content;
}

.quote-metgadata-popover-txt {
  font-size: 1rem;
  text-align: center;
  align-self: center;
  align-content: center;
  justify-content: center;
}
.quote-metgadata-popover-body {
  user-select: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--popup-background-color);
  color: var(--popup-text-color);
  max-width: 50vw;
  border-radius: var(--popup-border-raduis);
  margin-bottom: -1px;
  font-family: var(--theme-font-family) !important;
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
}
.quote-metgadata-popover .popover-arrow::after {
  border-top-color: var(--popup-background-color) !important;
}
