.container-auth-bottom-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  position: relative;
}

.txt-redirect-action-auth-bottom-bar {
  color: #fff;
  font-size: 1rem;
  text-align: center;
  margin: 0;
  cursor: pointer;
  white-space: nowrap;
  opacity: 0.9;
  width: 30%;
}

.txt-redirect-action-auth-bottom-bar:hover {
  text-decoration: underline;
  opacity: 1;
}
