.wallpaper-meavana-topic-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0 5px 5px 5px;
}

.wallpaper-meavana-topics-container-grid {
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 7px;
  transition: all 1000ms ease;
}

.wallpaper-meavana-topics-grid-template-colums-800 {
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}
.wallpaper-meavana-topics-grid-template-colums-800
  .wallpaper-meavana-topics-container-grid-item {
  height: 250px;
}
.wallpaper-meavana-topics-grid-template-colums-600 {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.wallpaper-meavana-topics-grid-template-colums-600
  .wallpaper-meavana-topics-container-grid-item {
  height: 200px;
}
.wallpaper-meavana-topics-grid-template-colums-400 {
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
.wallpaper-meavana-topics-grid-template-colums-400
  .wallpaper-meavana-topics-container-grid-item {
  height: 150px;
}

.wallpaper-meavana-topics-container-grid-item {
  display: flex;
  position: relative;
  line-height: 0;
  flex-direction: column;
  transition: all 1000ms ease;
  border-radius: 0.5em;

  background-color: var(--meavana-background-color);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: background-color 0.8s ease-in-out,
    background-image 3s cubic-bezier(0, 0, 0.2, 1);
  transition: all 3s cubic-bezier(0, 0, 0.2, 1);
  width: 100%;
  height: 100%;
}

.wallpaper-meavana-topics-image-grid-item-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
}

.wallpaper-meavana-topics-container-grid-item:hover
  .wallpaper-meavana-topics-action-container {
  opacity: 1;
}

.wallpaper-meavana-topics-action-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-image: linear-gradient(to top, #000000bf, transparent);
  opacity: 0;
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 0.5em;
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1rem;
}

.wallpaper-meavana-topics-action-container span {
  font-size: 1em;
  font-weight: 500;
}
