.wallpaper-upload-background-drop-file-input {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;

  color: var(--popup-text-color) !important;
}

.wallpaper-upload-background-drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
}

.wallpaper-upload-background-drop-file-input:hover,
.wallpaper-upload-background-drop-file-input.dragover {
  background-color: var(--popup-light-background-color) !important;
}
.dzu-dropzoneActive {
  background-color: var(--popup-light-background-color) !important;
}

.wallpaper-upload-background-drop-file-input__label {
  text-align: center;
  color: var(--popup-text-color) !important;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
}

.wallpaper-upload-background-drop-file-input__label p {
  margin-top: 0.2rem;
  margin: 0 !important;
  width: 100%;
  font-size: 0.8rem;
}

.wallpaper-upload-background-drop-file-input__label img {
  width: 100px;
}
