.wallpaper-unsplash-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0 5px 5px 5px;
}
.wallpaper-background-unsplash-image-grid {
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 2px;
  transition: all 1000ms ease;
}

.wallpaper-background-unsplash-grid-template-colums-800 {
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}
.wallpaper-background-unsplash-grid-template-colums-800
  .wallpaper-background-unsplash-image-grid-item {
  height: 300px;
}
.wallpaper-background-unsplash-grid-template-colums-600 {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.wallpaper-background-unsplash-grid-template-colums-600
  .wallpaper-background-unsplash-image-grid-item {
  height: 300px;
}
.wallpaper-background-unsplash-grid-template-colums-400 {
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
.wallpaper-background-unsplash-grid-template-colums-400
  .wallpaper-background-unsplash-image-grid-item {
  height: 250px;
}

.wallpaper-background-unsplash-image-grid-item {
  flex: 33%;
  display: flex;
  position: relative;
  line-height: 0;
  flex-direction: column;
  transition: all 1000ms ease;
}

.wallpaper-background-unsplash-image-grid-item-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
}

.wallpaper-background-unsplash-action-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-image: linear-gradient(to top, #000000bf, transparent);
  opacity: 0;
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1rem;
}

.wallpaper-background-unsplash-action-container span {
  font-size: 1em;
  font-weight: 500;
}

.wallpaper-background-unsplash-image-grid-item:hover
  .wallpaper-background-unsplash-action-container {
  opacity: 1;
}

.active-picture-unsplash-action-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-image: linear-gradient(to top, #000000bf, transparent);
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.wallpaper-background-unsplash-poweredby-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0px 10px;
  font-size: 1rem;
  font-weight: 400;
  opacity: 0.7;
}
