.container-btn-integration {
  border-radius: 50%;
  color: var(--meavana-icon-color) !important;
  cursor: pointer;
}

.container-btn-integration {
  z-index: 10;
  padding: 0.2rem;
  border-radius: 50%;
  color: var(--meavana-icon-color) !important;
  cursor: pointer;
  /* margin-left: 0.5rem;*/
}

.container-btn-integration:hover {
  background-color: #8da3b699 !important;
}

.icon-integration {
  width: 2.2rem !important;
  height: 2.2rem !important;
}

.popover-integration .popover-arrow::after {
  border-bottom-color: var(--popup-light-background-color) !important;
}

.popover-integration {
  user-select: none !important;
  color: var(--popup-text-color);
  background: var(--popup-background-color);
  border-radius: var(--popup-border-raduis);
  font-family: var(--theme-font-family) !important;
  border: 0;
  flex: 1;
  padding-bottom: 0.5em !important;
  margin-bottom: -1px;
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
  min-width: 300px;
  min-height: 142px;
}
.popover-integration-header {
  /* background: var(--popup-active-background-color);*/
  background: var(--popup-light-background-color);
  font-weight: 600;
  border-bottom: 1.4px solid var(--popup-light-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}

.popover-integration-header::before {
  border: none !important;
}

.popover-integration-body {
  user-select: none !important;
  color: var(--popup-text-color);
  background: transparent;

  transition: all 1s ease;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
}

.popover-integration-button-back {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  color: var(--popup-text-color) !important;
}

.Small-Size .popover-integration {
  font-size: var(--theme-font-small-size) !important;
}
.Medium-Size .popover-integration-body {
  font-size: var(--theme-font-meduim-size) !important;
}
.Large-Size .popover-integration-body {
  font-size: var(--theme-font-large-size) !important;
}

.integration-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 5px;
  width: 100%;
}

.integration-grid-item-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0 5px;
}
.integration-grid-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  padding: 5px;
  width: 100%;
}
.integration-grid-item:hover {
  background: var(--popup-light-background-color);
}
.integration-grid-item-active {
  background: var(--popup-light-background-color);
}
.integration-grid-item:hover .popover-integration-button-pin {
  opacity: 1;
}

.popover-integration-button-pin {
  position: absolute !important;
  right: 0px !important;
  top: -1px !important;
  color: var(--popup-text-color) !important;
  font-size: 1.2em !important;
  opacity: 0;
  z-index: 5;
  cursor: pointer;
}

.integration-grid-item-inactive .integration-grid-item-container {
  opacity: 0.7;
}
.integration-grid-item-inactive-msg-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: var(--popup-light-background-color);
  z-index: 5;
}
.integration-grid-item-inactive:hover .integration-grid-item-inactive-msg-container {
  opacity: 1;
}

.integration-grid-item img {
  width: 3em;
}
.integration-grid-item span {
  font-size: 0.7em;
  padding: 5px;
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  font-weight: 500;
}

/***** */
.position-bottom {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0%);
}

.WindowModal {
  z-index: 5 !important;
}

/*******************Pin items */

.news-pin-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 5px;
  width: 100%;
}

.news-pin-grid-item-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0 5px;
}

.news-pin-grid-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  padding: 5px;
  width: 100%;
}
.news-pin-grid-item:hover {
  background: var(--popup-light-background-color);
}
.news-pin-grid-item-active {
  background: var(--popup-light-background-color);
}
.news-pin-grid-item:hover .news-pin-button-pin {
  opacity: 1;
}

.news-pin-button-pin {
  position: absolute !important;
  right: 0px !important;
  top: 0px !important;
  color: var(--popup-text-color) !important;
  font-size: 1.2em !important;
  opacity: 0;
  z-index: 5;
  cursor: pointer;
}

.news-pin-grid-item-container img {
  width: 3em;
}
.news-pin-grid-item-container span {
  font-size: 0.7em;
  padding: 5px;
  max-width: 6em;
  text-align: center;
  text-transform: capitalize;
  font-weight: 500;
}

.marketing-btn {
  /* border: 2px solid white; */
  border-radius: 0.4rem !important;
  display: block;
  margin: 4px;
  min-height: 50px;
  background-color: #380c9c;
  font-size: 1.2rem;
  padding: 10px 15px !important;
}

.marketing-btn .marketing-btn-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-light {
  color: #bdbdbd !important;
}

.marketing-text-container {
  top: 7rem;
  left: 80%;
  text-align: left;
}

/* with screen size bigger than 960px */
@media (min-width: 960px) {
  .marketing-text-container {
    position: absolute;
  }
}
