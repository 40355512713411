.home-grid-container {
  display: grid;
  grid-template-columns: minmax(50px, auto) 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr minmax(85px, auto);
  grid-template-rows: minmax(50px, auto) 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr minmax(50px, auto);
  gap: 0px 0px;
  grid-auto-flow: row;
  justify-content: stretch;
  align-content: stretch;
  justify-items: stretch;
  align-items: stretch;
  grid-template-areas:
    'top-area top-area top-area top-area top-area top-area top-area top-area top-area top-area'
    'left-area center-area center-area center-area center-area center-area center-area center-area center-area right-area'
    'left-area center-area center-area center-area center-area center-area center-area center-area center-area right-area'
    'left-area center-area center-area center-area center-area center-area center-area center-area center-area right-area'
    'left-area center-area center-area center-area center-area center-area center-area center-area center-area right-area'
    'left-area center-area center-area center-area center-area center-area center-area center-area center-area right-area'
    'left-area center-area center-area center-area center-area center-area center-area center-area center-area right-area'
    'left-area center-area center-area center-area center-area center-area center-area center-area center-area right-area'
    'left-area center-area center-area center-area center-area center-area center-area center-area center-area right-area'
    'bottom-area bottom-area bottom-area bottom-area bottom-area bottom-area bottom-area bottom-area bottom-area bottom-area';
}

.home-grid-container {
  height: 100%;
  margin: 0;
  z-index: 4;

  width: 100vw;
  min-height: 100vh;
  color: #ffff;
  -webkit-text-stroke-width: 0 !important;
  transition: all 1000ms ease;

  text-shadow: 0px 0px 4px #000000cc;
}

.top-area {
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-auto-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: 'weather-area weather-area weather-area weather-area integration-area';
  grid-area: top-area;
}

.weather-area {
  grid-area: weather-area;
  padding: 15px 10px 10px 15px;
}

.integration-area {
  grid-area: integration-area;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 15px;
}

.left-area {
  grid-area: left-area;
  --scrollbar-background-color: white !important;
  --scrollbar-track-color: transparent !important;
  --scrollbar-track-border-color: transparent !important;
}

.center-area {
  grid-area: center-area;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  --scrollbar-background-color: white !important;
  --scrollbar-track-color: transparent !important;
  --scrollbar-track-border-color: transparent !important;
}

.Date-time-area {
  grid-area: Date-time-area;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
  z-index: 5;
}

.search-engine-area {
  grid-area: search-engine-area;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 270px;
  min-height: 50px;
  width: 100%;
}

.bookmarks-area {
  grid-area: bookmarks-area;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.right-area {
  grid-area: right-area;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 0.3rem;
  --scrollbar-background-color: white !important;
  --scrollbar-track-color: transparent !important;
  --scrollbar-track-border-color: transparent !important;
}

.bottom-area {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: 'bottom-left-area Quote-area Quote-area Quote-area Quote-area Quote-area Quote-area Quote-area bottom-right-area';
  grid-area: bottom-area;
}

.bottom-left-area {
  grid-area: bottom-left-area;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0 0px 10px 10px;
}

.Quote-area {
  grid-area: Quote-area;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-right-area {
  grid-area: bottom-right-area;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 10px 10px 0px;
}

.home-icon-font-size {
  font-size: 2em !important;
  border-radius: 50%;
  color: var(--meavana-icon-color) !important;
}
.home-icon-setting-font-size {
  font-size: 1.3em !important;
  border-radius: 50%;
  color: var(--meavana-icon-color) !important;
}

.home-icon-wether-main-size {
  width: 3em !important;
  opacity: var(--meavana-wether-icon-opacity);
}
.home-icon-wether-secondary-size {
  width: 2em !important;
  opacity: var(--meavana-wether-icon-opacity);
}

.popover-meavana-arrow-left .popover-arrow::after {
  border-left-color: var(--popup-background-color) !important;
}
.popover-meavana-arrow-right .popover-arrow::after {
  border-right-color: var(--popup-background-color) !important;
}
.popover-meavana-arrow-bottom .popover-arrow::after {
  border-bottom-color: var(--popup-background-color) !important;
}
.popover-meavana-arrow-top .popover-arrow::after {
  border-top-color: var(--popup-background-color) !important;
}
