.calendar-grid-container {
  margin-top: 10px;
}
.calendar-grid-container .calendar-menu {
  padding: 5px;
}
.calendar-grid-container .user-calendar-selector {
  padding-top: 4px;
  padding-bottom: 4px;
}
.calendar-grid-container .calendar-container {
  padding-left: 2px;
  padding-right: 2px;
}
