.container-btn-BackgroundInfo {
  z-index: 10;
  padding: 0.2rem;
  border-radius: 50%;
  color: var(--meavana-icon-color) !important;
  cursor: pointer;
  /* margin-left: 0.5rem;*/
}

.container-btn-BackgroundInfo:hover {
  background-color: #8da3b699 !important;
}

.popover-BackgroundInfo .popover-arrow::after {
  border-top-color: var(--popup-background-color) !important;
}

/*.popover-arrow::before {
   
    border-top-color: #303743bf !important;
  }*/

.popover-BackgroundInfo {
  background-color: transparent;

  border: 0;
}

.popover-BackgroundInfo-body {
  user-select: none !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--popup-background-color);
  color: var(--popup-text-color);
  min-height: fit-content;
  max-height: 90vh;
  min-width: 300px;
  max-width: 500px;
  width: 25vw;
  border-radius: var(--popup-border-raduis);
  margin-bottom: -1px;
  font-family: var(--theme-font-family) !important;
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
}

.backgound-info-image {
  max-width: 100%;
  max-height: 50vh;
}

.header-background-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.header-background-info-title {
  /*  color: #fff;*/
  font-size: 1.5em;
  width: 100%;
  text-align: left;

  font-weight: 500;
  margin: 0;
}

.header-background-info-subtitle {
  /* color: #fff;*/
  opacity: 0.75;
  font-size: 0.8em;
  width: 100%;
  text-align: left;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.container-active-backgound-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0.5rem;
  user-select: text !important;
  position: relative;
  /* margin-bottom: 40px;*/
}

.container-image-background {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 0.5rem 0;
}

.container-btn-like {
  position: absolute !important;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.icon-like-background-image {
  color: #fff !important;
}

.active-backgound-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 1px 6px 0 #20212447;
  transition: all 3s cubic-bezier(0, 0, 0.2, 1);
}

.container-backround-under-background {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.active-backgound-title {
  font-size: 1.2em;
  text-align: left;
  font-weight: 400;
  padding: 0;
  margin: 0;
}
.active-backgound-subtitle {
  opacity: 0.75;
  font-size: 1em;
  text-align: left;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.container-photographe {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 0.3rem;
}

.active-backgound-subtitle-photographe {
  opacity: 1;
  font-size: 1em;
  text-align: left;
  font-weight: 500;
  padding: 0;
  margin: 0;
  text-transform: capitalize;
}
.txt-date {
  white-space: nowrap;
  text-align: right;
}

.container-externam-url-background {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-top: 1rem;
}
.container-externam-url-background span {
  opacity: 1 !important;
}

.background-setting-container-submit {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: -2rem;
  right: 1rem;
}
.background-setting-btn-submit {
  color: #fff !important;
}

.background-extenal-url a:link {
  color: var(--popup-text-color);
  font-weight: 700 !important;
  opacity: 0.8 !important;
  background-color: transparent;
  text-decoration: underline;
}
.background-extenal-url a:visited {
  color: var(--popup-text-color);
  font-weight: 700 !important;
  opacity: 0.6 !important;
  background-color: transparent;
  text-decoration: underline;
}
.background-extenal-url a:hover {
  color: var(--popup-text-color);
  font-weight: 700 !important;
  opacity: 1 !important;
  background-color: transparent;
  text-decoration: underline;
}
.background-extenal-url a:active {
  color: var(--popup-text-color);
  font-weight: 700 !important;
  opacity: 0.9 !important;
  background-color: transparent;
  text-decoration: underline;
}
