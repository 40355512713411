.weather-component {
  z-index: 10;
  /* cursor: pointer;*/
}

.weather-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}
.weather-container-main-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 20vw;
  cursor: pointer;
}
/***************** Pin wethaer to home*****************************************************************************/

.container-weather-pin-home {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
  scroll-behavior: smooth;
  margin-left: 1em;
}

.weather-pin-txt-3 {
  font-size: 1.5em;
  margin-left: 0.5em;
  font-weight: 400;
  text-align: left;
  text-transform: capitalize;
}

.weather-pin-txt-2 {
  font-size: 1.25em;
  font-weight: 400;
  text-align: center;
  text-transform: capitalize;
  margin-top: -0.3rem;
}

.weather-pin-hourly-time-container-am-txt {
  font-size: 1em;
  margin-left: 2px;
  font-weight: 400;
  text-transform: capitalize;
}

.container-weather-pin-home-item-active {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
  border-radius: 5px;
  opacity: 1;
  cursor: pointer;
}

.container-weather-pin-home-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;

  border-radius: 5px;

  /* background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(4px) saturate(100%) contrast(60%) brightness(110%);*/

  /* 
  opacity: 0.85;
 background-color: rgba(255 ,255 ,255 , 0);
  backdrop-filter: blur(4px) saturate(100%) contrast(60%) brightness(110%);*/
}
/*
.container-weather-pin-home-item:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(4px) saturate(100%) contrast(60%) brightness(110%);
 
}
*/
/**********************************************************************************************/

.weather-container span {
  font-weight: 600 !important;
}

.container-img-temperature {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.weather-temperature-txt {
  font-size: 2em;
  font-weight: 400;
}

.container-weather-place {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /*margin-top: -0.3rem;*/
}

.weather-country {
  font-size: 1.25em;
  line-height: 1.2;
  text-align: left;
  font-weight: 400;
}

/************************************ popover weather********************/

.weather-popover {
  background: transparent;
  border: 0;

  min-width: fit-content;
}

.weather-popover-body {
  user-select: none !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 10px;
  color: var(--popup-text-color);
  background: var(--popup-background-color);

  overflow-x: hidden;
  scroll-behavior: smooth;

  border-radius: var(--popup-border-raduis);
  margin-top: -1px;
  font-family: var(--theme-font-family) !important;
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
}

.Small-Size .weather-popover-body {
  font-size: var(--theme-font-small-size) !important;
  min-width: 400px;
  width: 30vw;
  max-height: 80vh;
}
.Medium-Size .weather-popover-body {
  font-size: var(--theme-font-meduim-size) !important;
  min-width: 470px;
  width: 35vw;
  max-height: 80vh;
}
.Large-Size .weather-popover-body {
  font-size: var(--theme-font-large-size) !important;
  min-width: 500px;
  width: 40vw;
  max-height: 80vh;
}

.weather-popover-body::-webkit-scrollbar-track {
  margin: 0.5em;
  margin-left: 0 !important;
}

/******* SECTION WEATHER text style  ***********************************************************************/

.weather-popover-txt-place {
  font-size: 1.7em;
  font-weight: 600;
  text-align: left;
  text-transform: capitalize;
}
.weather-popover-txt-hourly {
  font-size: 1.3em;
  font-weight: 500;
  opacity: 0.8;
  text-align: left;
  text-transform: capitalize;
  padding: 0.5em 0 0 0;
}
.weather-hourly:hover .weather-popover-txt-hourly {
  font-weight: 600;
  opacity: 1;
}
.weather-popover-txt-weekly {
  font-size: 1.3em;
  font-weight: 500;
  opacity: 0.8;
  text-align: left;
  text-transform: capitalize;
  padding: 0.5em 0 0 0;
}
.weather-weekly:hover .weather-popover-txt-weekly {
  font-weight: 600;
  opacity: 1;
}

.weather-popover-txt-main-temp {
  font-size: 3.5em;
  font-weight: 400;
  text-align: center;
}
.weather-popover-txt-temp-unit {
  font-size: 1.5em;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
  opacity: 0.3;
  text-decoration: none;
  cursor: pointer;
}
.weather-popover-txt-temp-unit:hover {
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  opacity: 0.8;
  text-decoration: underline;
}
.weather-popover-txt-temp-unit-active {
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  opacity: 1;
  text-decoration: underline;
  cursor: pointer;
}

.weather-popover-txt-1 {
  font-size: 1em;
  font-weight: 400;
  text-align: left;
  text-transform: capitalize;
}
.weather-popover-txt-2 {
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
}

.weather-popover-txt-3 {
  font-size: 1em;
  margin-left: 0.5em;
  font-weight: 400;
  text-align: left;
  text-transform: capitalize;
}

.weather-popover-txt-dim {
  opacity: 0.7 !important;
  text-transform: none !important;
}
.weather-popover-txt-dim:hover {
  opacity: 1 !important;
  font-size: 500;
}

/******* SECTION WEATHER img style  ***********************************************************************/

.weather-popover-icon-main {
  width: 5em;
  margin: 0;
}

.weather-popover-icon-secondairy {
  width: 2em;
}

/******* SECTION WEATHER TODAY  ***********************************************************************/
.container-weather-today-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 5px 0;
}

.container-weather-today-main-form {
  background-color: transparent;
  display: flex;
}

.container-weather-today-main-form-input {
  flex: 1;
  outline: none;
  background: transparent;
  border: none;
  color: var(--popup-text-color) !important;
}

.container-weather-today-main-form-input
  .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--popup-text-color);
}
.container-weather-today-main-form-input
  .css-1ptx2yq-MuiInputBase-root-MuiInput-root {
  color: var(--popup-text-color);
}
.container-weather-today-main-form-input
  .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--popup-text-color);
}
.container-weather-today-main-form-input .css-1vv4lmi {
  color: var(--popup-text-color);
}
.container-weather-today-main-form-input .css-348fk2 {
  color: var(--popup-text-color);
}
.container-weather-today-main-form-input
  .css-1ptx2yq-MuiInputBase-root-MuiInput-root:before {
  color: var(--popup-text-color);
  border-bottom: 1px solid var(--popup-text-color);
}
.container-weather-today-main-form-input .css-1vv4lmi:before {
  color: var(--popup-text-color);
  border-bottom: 1px solid var(--popup-text-color);
}

.container-weather-today-main-location {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /*min-width: 150px;*/
}
.container-weather-today-main-sun-time {
  display: flex;
  flex-direction: column;
  max-width: 150px;
}

.item-weather-sun-time {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.container-weather-today-main-temp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: flex-end;
}

.container-weather-today-main-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.container-weather-today-main-cloud-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* min-height: 140px;*/
}
.container-weather-today-main-temp-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* min-height: 140px;*/
  margin-left: 1em;
}

.container-weather-today-main-temp-info-temp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.container-weather-today-main-temp-unit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
/******* SECTION WEATHER HOURLY ***********************************************************************/
.weather-hourly {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  border-top: 2px solid var(--popup-light-background-color);
}
.container-weather-hourly {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
}

.weather-btn-pin {
  font-size: 2em !important;
  font-weight: 400 !important;
  color: var(--popup-text-color) !important;
  position: absolute !important;
  right: 0;
  top: 0;
}

.weather-btn-edit-city {
  font-size: 2em !important;
  font-weight: 400 !important;
  color: var(--popup-text-color) !important;
}

.weather-hourly-btn-scroll-right {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  padding: 0.1em;
  opacity: 0.8;
  cursor: pointer;
  z-index: 10;
}
.weather-hourly-btn-scroll-left {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  padding: 0.1em;
  opacity: 0.8;
  cursor: pointer;
  z-index: 10;
}

.subcontainer-weather-hourly {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
  scroll-behavior: smooth;

  padding: 5px 0;
}

.container-weather-hourly-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
  padding: 6px;
  opacity: 0.85;
  border-radius: 5px;
  background-color: #8da2b71a;
}
.container-weather-hourly-item:hover {
  opacity: 1;
  background-color: var(--popup-light-background-color);
}

.container-weather-hourly-item-active {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
  padding: 6px;
  border-radius: 5px;
  opacity: 1;
  background-color: var(--popup-light-background-color);
}

.container-weather-hourly-item-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.weather-hourly-time-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  width: 100%;
}

.weather-hourly-time-container-am-txt {
  font-size: 0.7em;
  margin-left: 2px;
  font-weight: 400;
  text-transform: capitalize;
}

/******* SECTION WEATHER TODAY OTHER INFO  ***********************************************************************/
.container-weather-today-other-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;

  padding: 0.7em 0 0.5em 0;
}
.subcontainer-weather-today-other-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
}

.item-weather-today-other-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.item-weather-today-other-info .weather-popover-txt-dim {
  margin-left: 1em;
}

/******* SECTION WEATHER WEEKLY  ***********************************************************************/
.weather-weekly {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid var(--popup-light-background-color);
}

.container-weather-weekly {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.subcontainer-weather-weekly {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
  scroll-behavior: smooth;
  flex: 1;
  padding: 5px 0;
}

.container-weather-weekly-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
  padding: 6px;
  opacity: 0.85;
  border-radius: 5px;
  background-color: #8da2b71a;
}
.container-weather-weekly-item-active {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
  padding: 6px;
  opacity: 1;
  background-color: var(--popup-light-background-color);
  border-radius: 5px;
}

.container-weather-weekly-item:hover {
  opacity: 1;
  background-color: var(--popup-light-background-color);
}

.container-weather-weekly-item-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.weather-weekly-container-temp-max-min {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
/*******   ***********************************************************************/

.weather-hourly-btn-scroll-right:hover {
  opacity: 0.9;
}
.weather-hourly-btn-scroll-right:active {
  /* background-color: var(--popup-light-background-color);*/
  opacity: 1;
}
.weather-hourly-btn-scroll-left:hover {
  opacity: 0.9;
}
.weather-hourly-btn-scroll-left:active {
  /* background-color: var(--popup-light-background-color);*/
  opacity: 1;
}

.weather-popover .popover-arrow::after {
  border-bottom-color: var(--popup-background-color) !important;
}
