.note-section {
  width: 100%;
  height: 100%;

  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  scroll-behavior: smooth;
}

/********************  list section  ************************************/
.notes-list-section {
  height: 100%;
  flex: 2 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  border-right: 2px solid var(--popup-border-divider-color);
  background-color: transparent;
  border-top-left-radius: var(--popup-border-raduis);
  border-bottom-left-radius: var(--popup-border-raduis);
  padding-bottom: 0.8rem;
  max-width: 320px;
  min-width: 180px;
}

.item-list-action {
  width: 100%;
  border-bottom: 1px solid var(--popup-border-divider-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.note-btn-icon {
  color: var(--popup-text-color) !important;
}

.note-btn-icon span {
  font-size: 0.7em !important;
  font-weight: 500;
  margin-left: 0.5em;
}

/* Note more options */
.popover-note-options {
  min-width: fit-content;
  background-color: transparent;
  border: 0;
}

.popover-note-options-body {
  user-select: none !important;
  display: flex;
  flex-direction: column;
  background: var(--popup-background-color);
  color: var(--popup-text-color);
  padding: 0.5em !important;
  border-radius: var(--popup-border-raduis);
  margin-left: -1px;
  font-family: var(--theme-font-family) !important;
}

.popover-note-options-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
}

.popover-note-options .popover-arrow::after {
  border-right-color: var(--popup-background-color) !important;
}

.notes-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  overflow-x: hidden;
  padding-bottom: 0.5rem;
}

.notes-list-item-container {
  position: relative;
  width: 100%;
}

.notes-list-item {
  width: 100%;
  border-bottom: 1px solid var(--popup-border-divider-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  opacity: 0.8;
  padding: 0 0 0 10px;
}
.notes-list-item .note-btn-icon {
  opacity: 0;
}
.notes-list-item:hover .note-btn-icon {
  opacity: 1;
}

.notes-list-item-delete-container {
  width: 100%;
  background-color: rgba(141, 162, 183, 1) !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 5px;
  opacity: 1 !important;
}
.notes-list-item-delete-container span {
  font-size: 0.9em;
  font-weight: 500;
  flex: 1;
}

.notes-list-item-delete-container-action {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.notes-list-item span {
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1em;
  font-weight: 400;
}
.notes-list-item:hover span {
  font-weight: 500;
}
.notes-list-item:hover {
  opacity: 0.9;
  background-color: var(--popup-light-background-color) !important;
}

.active-note {
  opacity: 1;
  background-color: var(--popup-light-background-color) !important;
}

/********************  editor section  ************************************/

.notes-editor-section {
  height: 100%;
  flex: 5 1;
  flex-direction: column;
  display: flex;

  justify-content: flex-start;
  align-items: center;

  background-color: transparent;
  overflow-y: auto;
  overflow-x: hidden;
  border-top-right-radius: var(--popup-border-raduis);
  border-bottom-right-radius: var(--popup-border-raduis);
}

.notes-header-container {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.notes-header-action-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid var(--popup-border-divider-color);
}
.notes-header-tab-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}

.note-tab {
  background-color: rgba(140, 140, 140, 0.6);
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 16px;
  height: 40px;
  transition: 0.3s;
  font-size: 1em;
  font-weight: 500;
  opacity: 0.6;
  border: 1px solid var(--popup-border-divider-color);
  color: var(--popup-text-color);
  flex: 1;
}

/* Change background color of buttons on hover */
.note-tab:hover {
  /*background-color: rgba(140, 140, 140, 0.3);
  opacity: 0.9;*/

  background-color: rgba(140, 140, 140, 0.3);
  opacity: 0.8;
}

/* Create an active/current tablink class */
.note-tab-active {
  opacity: 1;
  background-color: transparent;
  flex: 1;
  outline: none;
  cursor: pointer;
  padding: 0 16px;
  height: 40px;
  transition: 0.3s;
  font-size: 1.1em;
  font-weight: 500;
  color: var(--popup-text-color);
  border: 1px solid var(--popup-border-divider-color);
  border-bottom: none !important;
}

.notes-editor-container {
  width: 100%;
  height: max-content;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

/******************editor form style************************/
.form-group-note {
  padding: 5px 0;
  width: 100%;
}

.form-group-note-editor {
  padding: 5px 0;
  width: 100%;
  flex: 1;
  height: max-content;
}

.form-group-note input {
  color: var(--popup-text-color);
  border: none;
  background: transparent;
  width: 100%;
  padding: 2px 5px;
  border-radius: none;
}

.border-bottom {
  border-bottom: 1px solid var(--popup-border-divider-color) !important;
}

.form-control-note:focus {
  /*border-color: #167bff;*/
  box-shadow: none;
}

.note-title-input {
  font-size: medium;
}

.note-title-input:focus-visible {
  /* don't show outline on focus visible */
  outline: unset;
}

/* Rich Text Editor */
.editor-container {
  opacity: 0.95;
  width: 100%;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
}
.editor-container .DraftEditor-root {
  /* height: 210px; */
  padding: 2px 5px;
  opacity: 0.95;
  overflow-y: auto;
  width: 100%;
  flex: 1;
  height: calc(100% - 47px);
}
.editor-container .DraftEditor-editorContainer,
.editor-container .public-DraftEditor-content {
  height: 100%;
}

.editor-toolbar {
  color: var(--popup-text-color);
  border-top: 1px solid var(--popup-border-divider-color);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.container-editor-toolbar-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  max-width: 300px;
}
.container-editor-toolbar-btn .btn {
  color: var(--popup-text-color);
  display: contents;
}

.editor-btn-tooltip {
  display: contents;
}
.editor-btn-tooltip .tooltip-inner {
  background-color: unset;
}
.container-editor-toolbar-btn .tooltip-arrow {
  display: none;
}
