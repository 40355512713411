.container-btn-Wallpaper {
  z-index: 10;

  padding: 0.2rem;
  border-radius: 50%;
  color: var(--meavana-icon-color) !important;
  cursor: pointer;
}

.container-btn-Wallpaper:hover {
  background-color: #8da3b699 !important;
}

.wallpaper-icon-font-size {
  width: 2.2rem !important;
  height: 2.2rem !important;
  border-radius: 50%;
  color: var(--meavana-icon-color) !important;
}
