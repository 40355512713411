.coincap-container {
  position: relative;
  padding: 5px;
}
.coincap-container .coincap-header {
  position: absolute;
  color: white;
  background: linear-gradient(to right, rgb(63, 81, 181), rgb(100, 181, 246))
    rgb(255, 255, 255);
  width: 100%;
  z-index: -10;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.coincap-container .assets-table {
  margin-top: 50px;
  width: "600px";
  max-height: "400";
  overflow: "auto";
}
