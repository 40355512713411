.unsplash-search-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px;
}
.unsplash-loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.unsplash-search-container form {
  display: flex;
  min-width: 295px;
  flex: 1;
}
.unsplash-switch-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
  font-weight: 500;
  margin: 0 0.5rem;
}

.window-integration-unsplash-image-grid {
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 2px;
  transition: all 1000ms ease;
}

.window-integration-unsplash-grid-template-colums-800 {
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}
.window-integration-unsplash-grid-template-colums-800
  .window-integration-unsplash-image-grid-item {
  height: 300px;
}
.window-integration-unsplash-grid-template-colums-600 {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.window-integration-unsplash-grid-template-colums-600
  .window-integration-unsplash-image-grid-item {
  height: 300px;
}
.window-integration-unsplash-grid-template-colums-400 {
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
.window-integration-unsplash-grid-template-colums-400
  .window-integration-unsplash-image-grid-item {
  height: 250px;
}

.window-integration-unsplash-image-grid-item {
  flex: 33%;
  display: flex;
  position: relative;
  line-height: 0;
  flex-direction: column;
  transition: all 1000ms ease;
}

.window-integration-unsplash-image-grid-item-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
}

.window-integration-unsplash-action-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-image: linear-gradient(to top, #000000bf, transparent);
  opacity: 0;
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.window-integration-unsplash-image-grid-item:hover
  .window-integration-unsplash-action-container {
  opacity: 1;
}

.active-picture-unsplash-action-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-image: linear-gradient(to top, #000000bf, transparent);
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}
