.container-btn-hide-all {
  z-index: 10;
  padding: 0.2rem;
  border-radius: 100px;
  color: var(--meavana-icon-color) !important;
  cursor: pointer;
  /* margin-left: 0.5rem;*/
}

.container-btn-hide-all:hover {
  background-color: #8da3b699 !important;
}
