.integration-window {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  text-shadow: none;

  color: var(--popup-text-color);
  background: var(--popup-background-color);
  border-radius: var(--popup-border-raduis);
  font-family: var(--theme-font-family) !important;
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
  border: 0;
  padding-bottom: 0.5rem;
  padding-right: 5px;
}

.rnd-window-container {
  background: transparent;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.Small-Size .integration-window-body {
  font-size: var(--theme-font-small-size) !important;
}
.Medium-Size .integration-window-body {
  font-size: var(--theme-font-meduim-size) !important;
}
.Large-Size .integration-window-body {
  font-size: var(--theme-font-large-size) !important;
}

.integration-window-header {
  width: calc(100% + 5px);
  height: 50px;

  background: var(--popup-light-background-color);

  border-top-left-radius: var(--popup-border-raduis);
  border-top-right-radius: var(--popup-border-raduis);

  border-bottom: 1.4px solid var(--popup-light-background-color);
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.integration-window-header:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.integration-window-header-center {
  font-weight: 600;
  font-size: 1.3em;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
}

.integration-window-header-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}
.integration-window-header-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.integration-window-header-btn {
  color: var(--popup-text-color) !important;
  z-index: 102;
  padding: 0.3rem;
  margin: 0.2rem;
  border-radius: 50%;
  cursor: pointer;
}

.integration-window-header-btn:hover {
  background-color: var(--popup-search-engine-background-color);
}
.integration-window-header-btn:active {
  background-color: var(--popup-active-search-engine-background-color);
}

.integration-window-body {
  width: 100%;
  height: 100%;

  flex: 1;
  background-color: transparent;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
