.trackSearchResult {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
  width: 100%;
  cursor: pointer;
  border-radius: 0.5rem;
}

.trackSearchResult:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.trackSearchResult > img {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 0.375rem;
}

.trackSearchResult__right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.trackSearchResult__rightTitle {
  font-size: 1rem;
  line-height: 1rem;
  width: 9rem;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.trackSearchResult__rightArtist {
  font-size: 0.775rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
