top-area {
  display: flex;
}
guest-area {
  position: relative;
  display: flex;
}

/* with screen size bigger than 960px */
@media (max-width: 960px) {
  top-area {
    flex-direction: column;
  }
  .guest-area {
    flex-direction: column;
  }
}
