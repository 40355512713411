.background-home {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  z-index: 1;

  background-color: var(--meavana-background-color);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: background-color 0.8s ease-in-out,
    background-image 3s cubic-bezier(0, 0, 0.2, 1);
  transition: all 3s cubic-bezier(0, 0, 0.2, 1);
  width: 100%;
  height: 100%;
}

.background-home:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: inherit;
  content: " ";
}

.background-animation-container {
  position: absolute;
  inset: 0;
  transition: all 3s cubic-bezier(0, 0, 0.2, 1);
}

.background-animation-fadeOut {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.6s, opacity 0.5s;
}
.background-animation-fadeIn {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s;
}
