.analog-clock {
  /*  width: 20vh;
  height: 20vh;*/
  min-width: 150px;
  min-height: 150px;
  border-radius: 50%;
  /* background: var(--popup-background-color);
  color: var(--popup-text-color);
   font-family: var(--theme-font-family) !important;
  */
  background: rgb(255, 255, 255);
  color: #000;

  /*font-size: 2vh !important;*/
  /* font-size: 15px;
 font-size: 2vmin !important;
 */
  margin-bottom: 1rem;
  text-shadow: none;
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
  font-family: "Roboto", sans-serif !important;
}

.light-theme {
  background-color: var(--analog-background-color);
  color: var(--analog-text-color);

  --analog-background-color: #fff;
  --analog-border-color: rgb(248 250 252 / 0.2);
  --analog-text-color: rgb(15 23 42 / 1);
  --analog-hand-color: rgb(15 23 42 / 1);
  --analog-mark-color: rgb(15 23 42 / 1);
}

.dark-theme {
  background-color: var(--analog-background-color);
  color: var(--analog-text-color);

  --analog-background-color: rgb(30 41 59 / 1);
  --analog-border-color: rgb(15 23 42 / 1);
  --analog-text-color: #fff;
  --analog-hand-color: #fff;
  --analog-mark-color: rgb(15 23 42 / 1);
}

.analog-clock .react-clock__mark__body {
  background-color: var(--analog-mark-color) !important;
}

.analog-clock .react-clock__face {
  /* border:0.75rem solid var(--analog-border-color) !important;*/
}
.analog-clock .react-clock__hand__body {
  background-color: var(--analog-hand-color) !important;
}

/* ************************************************************************************************ */
/* ************************************************************************************************ */
/* ************************************************************************************************ */
/* -------------------------------------- MODERN ANALOG CLOCK ------------------------------------- */
/* ************************************************************************************************ */
/* ************************************************************************************************ */
/* ************************************************************************************************ */

.modernAnalogClock {
  position: relative;
  padding: 0.8rem;
  display: grid;
  place-items: center;
  border-radius: 9999px;
  margin-bottom: 1rem;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.modernAnalogClock--light {
  --tw-ring-color: rgb(148 163 184 / 1);
  background-color: rgb(248 250 252 / 0.2);
}

.modernAnalogClock--dark {
  --tw-ring-color: rgb(30 41 59 / 1);
  background-color: rgb(15 23 42 / 1);
}

.modernAnalogClock--innerCirlce {
  padding: 0.75rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 9999px;
}

.modernAnalogClock--innerCirlceLight {
  --tw-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  --tw-shadow-colored: inset 0px 0px 15px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: rgb(255 255 255 / 1);
}
.modernAnalogClock--innerCirlceDark {
  background-color: rgb(30 41 59 / 1);
  --tw-shadow-color: #1e293b;
  --tw-shadow: var(--tw-shadow-colored);
}

.modernAnalogClock__container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-items: flex-end;
}

.modernAnalogClock__nums {
  position: absolute;
  width: 100%;
  transform-origin: center;
  display: flex;
  justify-content: flex-end;
}

.modernAnalogClock--primaryDots {
  width: 0.75rem;
}

.modernAnalogClock--primaryDotsLight {
  background-color: #0f172a;
}
.modernAnalogClock--primaryDotsDark {
  background-color: rgb(241 245 249 / 1);
}

.modernAnalogClock--secondaryDots {
  width: 0.25rem;
}

.modernAnalogClock--secondaryDotsLight {
  background-color: #1e293b;
}
.modernAnalogClock--secondaryDotsDark {
  background-color: rgb(241 245 249 / 1);
}

/* seconds */
.modernAnalogClock__seconds {
  position: absolute;
  width: 50%;
  height: 2.5px;
  border-radius: 9999px;
  transform-origin: left;
  left: 50%;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.modernAnalogClock__secondsLight {
  background-color: #0f172a;
}

.modernAnalogClock__secondsDark {
  background-color: whitesmoke;
}

/* minutes */
.modernAnalogClock__minutes {
  position: absolute;
  width: 50%;
  height: 5px;
  transform-origin: left;
  left: 50%;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.modernAnalogClock__minutes--line {
  width: 83.333333%;
  height: 100%;
  margin-right: auto;
  border-radius: 9999px;
  border: 1.6px solid;
  background-color: transparent;
}

.modernAnalogClock__minutes--lineLight {
  border-color: #0f172a;
}
.modernAnalogClock__minutes--lineDark {
  border-color: whitesmoke;
}

/* hours */
.modernAnalogClock__hours {
  position: absolute;
  width: 50%;
  height: 6px;
  transform-origin: left;
  left: 50%;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 1000ms;
}
.modernAnalogClock__hours--line {
  width: 66.666667%;
  height: 100%;
  border-radius: 9999px;
  border: 1.6px solid;
  background-color: transparent;
}

.modernAnalogClock__hours--lineLight {
  border-color: #0f172a;
}
.modernAnalogClock__hours--lineDark {
  border-color: whitesmoke;
}

.modernAnalogClock__centerDot {
  position: absolute;
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
}

.modernAnalogClock__centerDot--inner {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 9999px;
}

.modernAnalogClock__centerDot--innerLight {
  background-color: #0f172a;
}
.modernAnalogClock__centerDot--innerDark {
  background-color: whitesmoke;
}
