.spotifyWindow__body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 15px;
  padding: 5px;
}

.spotifyWindow__header {
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 0.5rem;
}

.spotifyWindow__bodyForm {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding: 0.25rem /* 4px */;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  border-style: none;
}

.spotifyWindow__bodyForm:focus-within {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.spotifyWindow__bodyFormInput {
  outline: none;
  border: none;
  width: 100%;
  background-color: transparent;
  color: whitesmoke;
}

.spotifyWindow__bodySongsList {
  flex: 1;
  overflow-y: auto;
}

/* error */

.errorMessage {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem;
  margin: 1.25rem 0 1.25rem 0;
}
