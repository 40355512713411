.spotifyPlayer {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;

  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.spotifyPlayer__grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  font-size: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.25rem 1.5rem;
  width: 100%;
}

.spotifyPlayer__gridLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column: 1 / span 2;
  /* border: 1px solid white; */
  column-gap: 1.5rem;
}

.spotifyPlayer__gridLeftImg {
  display: inline;
  height: 2.5rem;
  width: 2.5rem;
}

.spotifyPlayer__gridLeftSongInfo {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-items: center;
  padding: 0 !important;
  margin: auto 0 !important;
  row-gap: 0.5rem;
}

.spotifyPlayer__gridLeftSongName {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 1rem;
  line-height: 1rem;
}

.spotifyPlayer__gridLeftArtistName {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 0.775rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

/* Right */

.spotifyPlayer__gridRight {
  column-gap: 0.15rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  grid-column: span 2 / span 2;
}

/* Right Player */
.spotifyPlayer__gridRightPlayer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-column: span 2 / span 2;
  /* border: 1px solid white; */
}

.spotifyPlayer__gridRightPlayerIcon {
  cursor: pointer !important;
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 1rem !important;
  transition: all 500ms ease !important;
}

.spotifyPlayer__gridRightPlayerIcon:hover {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

/* Right Volume */

.spotifyPlayer__gridRightVolume {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: end;
}
