.bookmarks-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  max-width: 50vw;
  padding: 5px 0 0 0 !important;
  z-index: 10;
  position: relative;
  text-shadow: none !important;
}
.bookmarks-container-large {
  max-height: 165px;

  min-width: 310px;
}
.bookmarks-container-small {
  max-height: 125px;

  min-width: 300px;
}

.bookmark-sm {
  max-height: 120px !important;
}

.bookmark-lg {
  max-height: 160px !important;
}

.bookmarks-container-sortable-list {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
  height: fit-content;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  transition: all 500ms ease-in-out;
  overflow-x: hidden;
}

.bookmarks-update-item {
  position: absolute;
}

.bookmark-add-btn {
  color: var(--meavana-icon-color);
  width: 100%;
  height: 100%;
}

.bookmark-btn-delete-all {
  width: fit-content;
  align-self: center;
}

.bookmarks-item {
  position: relative;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  margin: 8px;
  background-color: rgba(255, 255, 255, 0.702);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease-out 0s, box-shadow 0.2s ease-out 0s;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.752);
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
}

.bookmark-lg .bookmarks-item {
  width: 60px;
  height: 60px;
}

.bookmark-lg .bookmarks-item:hover .bookmark-img {
  width: 35px;
}
.bookmark-lg .bookmarks-item .bookmark-img {
  width: 32px;
}

.bookmarks-item:hover {
  /*background: #dadada;*/
  background-color: #fff;
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
}

.bookmarks-item:hover .bookmark-img {
  width: 30px;
}
.bookmarks-add-item:hover {
  background: #dadada;
}
.bookmarks-item:hover span {
  color: #1189ad;
}

.bookmarks-add-item:hover span {
  color: #1189ad;
}

.bookmarks-item-container {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  opacity: 1;
  width: inherit;
}

.bookmarks-item-container span {
  position: absolute;
  font-size: 15px;
  font-weight: 500;
  color: rgb(49, 56, 69, 1);
}

.bookmark-img {
  width: 27px;
  transition: width 300ms ease-in-out;
}

.grayscale-filter {
  filter: grayscale();
}

.rectangular-shape {
  border-radius: 15%;
}

.bookmark-txt {
  user-select: none;
  letter-spacing: 0px;
  font-size: 0.8em;
  font-weight: 500;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.bookmark-txt:hover {
  color: rgb(14, 84, 105);
}

.more-bookmark-option-icon {
  position: absolute;
  top: 0;
  right: 0;

  border-radius: 50%;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  cursor: pointer;
  color: #fff;
  display: block;
}

.delete-bookmark-btn {
  position: absolute !important;
  top: -5px;
  right: -5px;
  z-index: 300;
  padding: 0 !important;
  cursor: pointer;
  background-color: #ffffffb3 !important;
  /* background: #ffffff80 !important;*/
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 1rem !important;
  display: none !important;
  transition: all 500ms ease;
}

.bookmarks-item:hover .delete-bookmark-btn {
  display: flex !important;
}
.delete-bookmark-btn:hover {
  display: flex !;
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.6) !important;
}
.container-btn-add-bookmark {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  margin-right: 10px;
}
.container-preview-add-bookmark {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  width: 60%;
}

.container-preview-add-bookmark label {
  padding: 0 7px;
  color: var(--popup-text-color);
}

.form-group-bookmark {
  padding: 5px 0;
  width: 20vw;
  min-width: 350px;
  max-width: 450px;
}

.form-group-bookmark label {
  padding: 5px 0;
  color: var(--popup-text-color);
}

.form-control-bookmark:focus {
  /*border-color: #167bff;*/
  box-shadow: none;
}

/* Bookmark more options */
.popover-bookmark-options {
  min-width: fit-content;
  background-color: transparent;
  border: 0;
}

.popover-bookmark-options-arrow-right .popover-arrow::after {
  border-right-color: var(--popup-background-color) !important;
}
.popover-bookmark-options-arrow-top .popover-arrow::after {
  border-top-color: var(--popup-background-color) !important;
}

.popover-bookmark-options-body {
  user-select: none !important;
  display: flex;
  flex-direction: column;
  background: var(--popup-background-color);
  color: var(--popup-text-color);
  padding: 0.5rem 1rem;
  border-radius: var(--popup-border-raduis);
  font-family: var(--theme-font-family) !important;
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
}

.popover-bookmark-options-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
}

.bookmark-options-btn {
  position: absolute !important;
  right: -55px;
}

/******** popover bookmark-folder*****************/
.bookmark-folder-popover {
  background: transparent;
  border: 0;
}

.bookmark-folder-popover-body {
  background: var(--popup-background-color);
  color: var(--popup-text-color);
  border-bottom-left-radius: var(--popup-border-raduis);
  border-bottom-right-radius: var(--popup-border-raduis);
  padding: 0.5rem 0.5em;
  max-height: 35vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  margin-top: -1px;
  max-width: 300px;
  font-family: var(--theme-font-family) !important;
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
}

.popover-header {
  background: var(--popup-background-color-opaque);
  color: var(--popup-text-color);
  border-top-left-radius: var(--popup-border-raduis);
  border-top-right-radius: var(--popup-border-raduis);
}
.bookmark-folder-popover-body::-webkit-scrollbar {
  display: none;
}

.bookmark-folder-popover .popover-arrow::after {
  border-top-color: var(--popup-background-color) !important;
}

.bookmark-folder-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0;
}

.bookmark-folder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: 3px 0;
  padding: 4px 1px;
  color: var(--popup-text-color);
  width: 100%;
  border-radius: 5px;
}

.bookmark-folder span {
  width: 80%;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bookmark-folder:hover {
  background: var(--popup-hover-search-engine-background-color);
}

.bookmark-folder img {
  width: 20px;
  margin-left: 5px;
}

.hide-scroll-bar::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}
.show-scroll-bar::-webkit-scrollbar-thumb {
  background-color: #ffff !important;
}

.error-msg-txt {
  color: #cb0101;
  font-size: 0.9rem;
  font-weight: 500;
  width: 100%;
  padding: 0 0 0 5px;
  margin: 0;
  text-align: left;
  min-height: 22px;
}
