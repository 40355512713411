/*********************** TimeFeaturesComponents Style***************************************************/
.container-TimeFeaturesComponents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 43vh;
  max-height: 54vh;
  z-index: 100;
  text-shadow: none;
  box-shadow: 0 1px 6px 0 var(--meavana-box-shadow-color) !important;
}
/****************************************************************************/

/********** StopwatchComponent style***********************************************/

.Stopwatch-container {
  padding: 2rem;
  box-shadow: 0 1px 6px 0 #20212447;
  margin: 0 auto;
  text-align: center;
  background: var(--popup-background-color);
  color: var(--popup-text-color);
  border-radius: var(--popup-border-raduis);
  z-index: 101;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Stopwatch-display {
  margin-bottom: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}

.Stopwatch-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
}

/********** TimerComponent style***********************************************/

.Timer-container {
  padding: 2rem;
  box-shadow: 0 1px 6px 0 #20212447;
  margin: 0 auto;
  text-align: center;
  background: var(--popup-background-color);
  color: var(--popup-text-color);
  border-radius: var(--popup-border-raduis);
  z-index: 101;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 230px;
}

.Timer-buttons {
  margin-top: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-input-group-timer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 2rem;
  color: var(--popup-text-color) !important;
}

.input-timer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*color: var(--popup-text-color);*/
  font-size: 1rem;
}
.input-timer {
  width: 30px;
  background: #d9d9d9;
  color: #000;
  font-size: 0.5rem;
  border: 1px solid var(--popup-border-divider-color);
  display: block;
  margin: 0.1rem 0;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-icons-arrow-timer-rotated {
  transform: rotate(180deg);
}

.btn-icons-arrow-timer {
  color: var(--popup-text-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100%;
  border-radius: 0.25rem;
  background: transparent;
  border: 0;
}

.btn-icons-arrow-timer:hover {
  background-color: var(--popup-search-engine-background-color);
}
.btn-icons-arrow-timer:active {
  background-color: var(--popup-active-search-engine-background-color);
}
.btn-icons-arrow-timer:disabled {
  opacity: 0.5;
}

.txt-countdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 86%;
  width: 86%;
  border-radius: 100px;
  /* background: #ffffffcc;*/
  background: #d9d9d9;
  font-size: 1.5rem;
}

.btn-icons-timer {
  color: var(--popup-text-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.6rem;
  padding: 5px;
  border-radius: 100px;
  cursor: pointer;
}

.btn-icons-timer:hover {
  background-color: var(--popup-search-engine-background-color);
}
.btn-icons-timer:active {
  background-color: var(--popup-active-search-engine-background-color);
}

.input-select-timer-audio {
  border-color: var(--popup-active-search-engine-background-color);
  box-shadow: none;
  cursor: pointer;
}

/********** AlarmComponent style***********************************************/

/****** add alarm form *******/

.AddAlarm-form-container {
  padding: 2rem 1rem 1.1rem 1rem;
  box-shadow: 0 1px 6px 0 #20212447;
  margin: 0 auto;
  text-align: center;
  background: var(--popup-background-color);
  color: var(--popup-text-color);
  border-radius: var(--popup-border-raduis);
  z-index: 105;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  min-width: 200px !important;
  color: #fff !important;
  border-color: #fff !important;
  outline-color: #fff !important;
}

.Alarm-container {
  padding: 2rem 1rem 1.1rem 1rem;
  box-shadow: 0 1px 6px 0 #20212447;
  margin: 0 auto;
  text-align: center;
  background: var(--popup-background-color);
  color: var(--popup-text-color);
  border-radius: var(--popup-border-raduis);
  z-index: 101;
  min-width: 200px;
}

.alarms-list-container {
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.stop-alarm {
  margin-bottom: 1rem;
  border: 1px solid black;
  border-radius: 15px;
  cursor: pointer;
  padding: 5px;
}

.item-Alarm-time-name {
  font-size: 14px;
  padding-bottom: 0px !important;
  margin-bottom: 0;
  opacity: 0.7;
}

.item-Alarm-time-value {
  font-size: 1.5rem;
  padding-right: 3px;
  margin-bottom: 0;
}

.item-Alarm-time-component {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.item-Alarm-time-meredian {
  font-size: 16px;
  margin-bottom: 5px;
  opacity: 0.8;
}

.item-Alarm-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--popup-text-color);
  min-width: 200px;
  font-size: 1.5rem;
  margin: 0.2rem;
}

.item-Alarm-container-icon {
  padding: 5px;
  background-color: #dc2626;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: grid;
  placeitems: center;
  cursor: pointer;
}

.item-Alarm-Form-container {
  color: var(--popup-text-color) !important;
  min-width: 200px;
  font-size: 1.5rem;
  margin: 0.2rem;
  flex: 1;
  outline: none;
  background: transparent;
  border: none;
}

.item-Alarm-Form-container .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--popup-text-color) !important;
}

.item-Alarm-Form-container .MuiFormLabel-root {
  color: var(--popup-text-color) !important;
}

.item-Alarm-Form-container .css-1ptx2yq-MuiInputBase-root-MuiInput-root {
  color: var(--popup-text-color) !important;
}
.item-Alarm-Form-container .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--popup-text-color) !important;
}
.item-Alarm-Form-container .css-1vv4lmi {
  color: var(--popup-text-color) !important;
}
.item-Alarm-Form-container .css-348fk2 {
  color: var(--popup-text-color) !important;
}
.item-Alarm-Form-container .css-1ptx2yq-MuiInputBase-root-MuiInput-root:before {
  color: var(--popup-text-color) !important;
  border-bottom: 1px solid var(--popup-text-color) !important;
}
.item-Alarm-Form-container .css-1vv4lmi:before {
  color: var(--popup-text-color) !important;
  border-bottom: 1px solid var(--popup-text-color) !important;
}

/* input[type=time] */
.item-Alarm-Form-time-container {
  border: none;
  color: var(--popup-text-color) !important;
  min-width: 200px;
  font-size: 1.5rem;
  margin: 0.2rem;
  outline: none;
  border-radius: 5px;
  background-color: var(--popup-background-color) !important;
}

/* Wrapper around the hour, minute, second, and am/pm fields as well as 
the up and down buttons and the 'X' button */
.item-Alarm-Form-time-container::-webkit-datetime-edit-fields-wrapper {
  display: flex;
  /* background-color: var(--popup-background-color) !important; */
}

/* The space between the fields - between hour and minute, the minute and 
second, second and am/pm */
.item-Alarm-Form-time-container::-webkit-datetime-edit-text {
  padding: 11px 4px;
}

/* The naming convention for the hour, minute, second, and am/pm field is
`-webkit-datetime-edit-{field}-field` */

/* Hour */
.item-Alarm-Form-time-container::-webkit-datetime-edit-hour-field {
  /* background-color: var(--popup-background-color) !important; */
  border-radius: 15%;
  padding: 11px 13px;
}

/* Minute */
.item-Alarm-Form-time-container::-webkit-datetime-edit-minute-field {
  /* background-color: var(--popup-background-color) !important; */
  border-radius: 15%;
  padding: 11px 13px;
}

/* AM/PM */
.item-Alarm-Form-time-container::-webkit-datetime-edit-ampm-field {
  background-color: #7155d3 !important;
  border-radius: 15%;
  color: var(--popup-text-color) !important;
  padding: 19px 13px;
}

/* 'X' button for resetting/clearing time */
.item-Alarm-Form-time-container::-webkit-clear-button {
  display: none;
}

/* Up/Down arrows for incrementing/decrementing the value */
.item-Alarm-Form-time-container::-webkit-inner-spin-button {
  display: none;
}

/* calender-picker clock styling */
.item-Alarm-Form-time-container::-webkit-calendar-picker-indicator {
  filter: invert(50%) sepia(15%) saturate(3210%) hue-rotate(130deg)
    brightness(95%) contrast(90%);
  margin-right: 4px;
}

/****************************************************************/

.Alarm-btn-add {
  color: var(--popup-search-engine-text-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: center;

  margin-top: 1.5rem !important;
  border-radius: 50px !important;
  padding: 7px !important;
  width: fit-content !important;
  min-width: 0 !important;
}

/********** TimeFeatures error style***********************************************/

.TimeFeatures-error-container {
  padding: 2rem;
  box-shadow: 0 1px 6px 0 #20212447;
  margin: 0 auto;
  text-align: center;
  background: var(--popup-background-color);
  color: var(--popup-text-color);
  border-radius: var(--popup-border-raduis);
  z-index: 101;
}

.btn-close-time-feature {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--popup-text-color) !important;
  z-index: 102;
  padding: 0.2rem;
  margin: 0.3rem;
  border-radius: 100px;
  cursor: pointer;
  /* color:#00bb1f ;
    color:#d8e704 ;
    color:#e7d004 ;
    color:#e7af04 ;*/
}

.btn-close-time-feature:hover {
  background-color: var(--popup-search-engine-background-color);
}
.btn-close-time-feature:active {
  background-color: var(--popup-active-search-engine-background-color);
}

.btn-back-time-feature {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--popup-text-color) !important;
  z-index: 102;
  padding: 0.2rem;
  margin: 0.3rem;
  border-radius: 100px;
  cursor: pointer;
  /* color:#00bb1f ;
  color:#d8e704 ;
  color:#e7d004 ;
  color:#e7af04 ;*/
}

.btn-back-time-feature:hover {
  background-color: var(--popup-search-engine-background-color);
}
.btn-back-time-feature:active {
  background-color: var(--popup-active-search-engine-background-color);
}

.btn-delete-time {
  color: var(--popup-text-color) !important;
  /* padding: 0.2rem; */
  /* margin: 0.3rem; */
  border-radius: 100px;
  cursor: pointer;
  /* color:#00bb1f ;
    color:#d8e704 ;
    color:#e7d004 ;
    color:#e7af04 ;*/
}

.btn-delete-time:hover {
  color: var(--popup-search-engine-background-color) !important;
}
.btn-delete-time:active {
  color: var(--popup-active-search-engine-background-color) !important;
}
